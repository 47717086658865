var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.liberaTela)?_c('div',{staticClass:"row-sonar sb-top"},[_c('div',{staticClass:"sonar-box sb-right2",class:{ flipping: _vm.flipCards[0] },staticStyle:{"width":"135px"},on:{"mousedown":function($event){return _vm.handleMouseDown('0')},"mouseup":function($event){return _vm.handleMouseUp('0')},"mouseleave":function($event){return _vm.handleMouseLeave('0')}}},[_c('div',{staticClass:"frente"},[_c('div',{class:['box-titulo', _vm.tipoExibidoLinhas[0]],attrs:{"id":'BlocoSonar-geracao-frente'}},[_c('img',{staticClass:"img-titulo gerac",attrs:{"src":_vm.iconGeracao}}),_c('span',{staticClass:"titulo-texto gerac"},[_vm._v(" Geração ")]),(_vm.isFlipping)?_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(
              _vm.flipCards[0] != _vm.isFlipping &&
              (_vm.tipoExibidoLinhas[0] == 'brasil' ||
                _vm.tipoExibidoLinhas[0] == 'documento')
            )?_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.flipCards[0] != _vm.isFlipping),expression:"flipCards[0] != isFlipping"}],key:'icon' + _vm.tipoExibidoLinhas[0],staticClass:"img-tipo",attrs:{"src":_vm.flipCards[0] != _vm.isFlipping && _vm.tipoExibidoLinhas[0] == 'brasil'
                ? _vm.perfilBrasilDesligado
                : _vm.perfilCpfDesligado}}):_vm._e()]):_vm._e()],1),_c('Geracao',{attrs:{"tipoExibido":_vm.tipoExibidoLinhas[0],"resultados":_vm.resultados,"lado":'frente'}})],1),(!_vm.gerandoPdf)?_c('div',{staticClass:"verso"},[_c('div',{class:['box-titulo', _vm.tipoExibidoLinhas[0]],attrs:{"id":'BlocoSonar-geracao-verso'}},[_c('img',{staticClass:"img-titulo gerac",attrs:{"src":_vm.iconGeracao}}),_c('span',{staticClass:"titulo-texto gerac"},[_vm._v(" Geração ")]),(!_vm.isFlipping)?_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(
              _vm.flipCards[0] != _vm.isFlipping &&
              (_vm.tipoExibidoLinhas[0] == 'brasil' ||
                _vm.tipoExibidoLinhas[0] == 'documento')
            )?_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.flipCards[0] != _vm.isFlipping),expression:"flipCards[0] != isFlipping"}],key:'icon' + _vm.tipoExibidoLinhas[0],staticClass:"img-tipo",attrs:{"src":_vm.flipCards[0] != _vm.isFlipping && _vm.tipoExibidoLinhas[0] == 'brasil'
                ? _vm.perfilBrasilDesligado
                : _vm.perfilCpfDesligado}}):_vm._e()]):_vm._e()],1),_c('Geracao',{attrs:{"tipoExibido":_vm.tipoExibidoLinhas[0],"resultados":_vm.resultados,"lado":'verso'}})],1):_vm._e()]),_c('div',{staticClass:"sonar-box sb-right2",class:{ flipping: _vm.flipCards[1] },staticStyle:{"width":"115px"},on:{"mousedown":function($event){return _vm.handleMouseDown('1')},"mouseup":function($event){return _vm.handleMouseUp('1')},"mouseleave":function($event){return _vm.handleMouseLeave('1')}}},[_c('div',{staticClass:"frente"},[_c('div',{class:['box-titulo', _vm.tipoExibidoLinhas[1]],attrs:{"id":"BlocoSonar-genero-frente"}},[_c('img',{staticClass:"img-titulo genero",attrs:{"src":_vm.iconGenero}}),_vm._m(0),(_vm.isFlipping)?_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(
              _vm.flipCards[1] != _vm.isFlipping &&
              (_vm.tipoExibidoLinhas[1] == 'brasil' ||
                _vm.tipoExibidoLinhas[1] == 'documento')
            )?_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.flipCards[1] != _vm.isFlipping),expression:"flipCards[1] != isFlipping"}],key:'icon' + _vm.tipoExibidoLinhas[1],staticClass:"img-tipo",attrs:{"src":_vm.flipCards[1] != _vm.isFlipping && _vm.tipoExibidoLinhas[1] == 'brasil'
                ? _vm.perfilBrasilDesligado
                : _vm.perfilCpfDesligado}}):_vm._e()]):_vm._e()],1),_c('GeneroInferido',{attrs:{"tipoExibido":_vm.tipoExibidoLinhas[1],"resultados":_vm.resultados}})],1),(!_vm.gerandoPdf)?_c('div',{staticClass:"verso"},[_c('div',{class:['box-titulo', _vm.tipoExibidoLinhas[1]],attrs:{"id":"BlocoSonar-genero-verso"}},[_c('img',{staticClass:"img-titulo genero",attrs:{"src":_vm.iconGenero}}),_vm._m(1),(!_vm.isFlipping)?_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(
              _vm.flipCards[1] != _vm.isFlipping &&
              (_vm.tipoExibidoLinhas[1] == 'brasil' ||
                _vm.tipoExibidoLinhas[1] == 'documento')
            )?_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.flipCards[1] != _vm.isFlipping),expression:"flipCards[1] != isFlipping"}],key:'icon' + _vm.tipoExibidoLinhas[1],staticClass:"img-tipo",attrs:{"src":_vm.flipCards[1] != _vm.isFlipping && _vm.tipoExibidoLinhas[1] == 'brasil'
                ? _vm.perfilBrasilDesligado
                : _vm.perfilCpfDesligado}}):_vm._e()]):_vm._e()],1),_c('GeneroInferido',{attrs:{"tipoExibido":_vm.tipoExibidoLinhas[1],"resultados":_vm.resultados}})],1):_vm._e()]),_c('div',{staticClass:"sonar-box sb-right2",class:{ flipping: _vm.flipCards[2] },staticStyle:{"width":"215px"},on:{"mousedown":function($event){return _vm.handleMouseDown('2')},"mouseup":function($event){return _vm.handleMouseUp('2')},"mouseleave":function($event){return _vm.handleMouseLeave('2')}}},[_c('div',{staticClass:"frente"},[_c('div',{class:['box-titulo', _vm.tipoExibidoLinhas[2]],attrs:{"id":"BlocoSonar-momento-frente"}},[_c('img',{staticClass:"img-titulo momento",attrs:{"src":_vm.iconMomento}}),_vm._m(2),(_vm.isFlipping)?_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(
              _vm.flipCards[2] != _vm.isFlipping &&
              (_vm.tipoExibidoLinhas[2] == 'brasil' ||
                _vm.tipoExibidoLinhas[2] == 'documento')
            )?_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.flipCards[2] != _vm.isFlipping),expression:"flipCards[2] != isFlipping"}],key:'icon' + _vm.tipoExibidoLinhas[2],staticClass:"img-tipo",attrs:{"src":_vm.flipCards[2] != _vm.isFlipping && _vm.tipoExibidoLinhas[2] == 'brasil'
                ? _vm.perfilBrasilDesligado
                : _vm.perfilCpfDesligado}}):_vm._e()]):_vm._e()],1),_c('MomentoVida',{attrs:{"tipoExibido":_vm.tipoExibidoLinhas[2],"resultados":_vm.resultados}})],1),(!_vm.gerandoPdf)?_c('div',{staticClass:"verso"},[_c('div',{class:['box-titulo', _vm.tipoExibidoLinhas[2]],attrs:{"id":"BlocoSonar-momento-verso"}},[_c('img',{staticClass:"img-titulo momento",attrs:{"src":_vm.iconMomento}}),_vm._m(3),(!_vm.isFlipping)?_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(
              _vm.flipCards[2] != _vm.isFlipping &&
              (_vm.tipoExibidoLinhas[2] == 'brasil' ||
                _vm.tipoExibidoLinhas[2] == 'documento')
            )?_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.flipCards[2] != _vm.isFlipping),expression:"flipCards[2] != isFlipping"}],key:'icon' + _vm.tipoExibidoLinhas[2],staticClass:"img-tipo",attrs:{"src":_vm.flipCards[2] != _vm.isFlipping && _vm.tipoExibidoLinhas[2] == 'brasil'
                ? _vm.perfilBrasilDesligado
                : _vm.perfilCpfDesligado}}):_vm._e()]):_vm._e()],1),_c('MomentoVida',{attrs:{"tipoExibido":_vm.tipoExibidoLinhas[2],"resultados":_vm.resultados}})],1):_vm._e()]),_c('div',{staticClass:"sonar-box sb-3",class:{ flipping: _vm.flipCards[3] },on:{"mousedown":function($event){return _vm.handleMouseDown('3')},"mouseup":function($event){return _vm.handleMouseUp('3')},"mouseleave":function($event){return _vm.handleMouseLeave('3')}}},[_c('div',{staticClass:"frente"},[_c('div',{class:['box-titulo', _vm.tipoExibidoLinhas[3]],attrs:{"id":"BlocoSonar-associacoes-frente"}},[_c('img',{staticClass:"img-titulo assoc",attrs:{"src":_vm.iconAssoc}}),_c('span',{staticClass:"titulo-texto assoc"},[_vm._v(" Associações ")]),(_vm.isFlipping)?_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(
              _vm.flipCards[3] != _vm.isFlipping &&
              (_vm.tipoExibidoLinhas[3] == 'brasil' ||
                _vm.tipoExibidoLinhas[3] == 'documento')
            )?_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.flipCards[3] != _vm.isFlipping),expression:"flipCards[3] != isFlipping"}],key:'icon' + _vm.tipoExibidoLinhas[3],staticClass:"img-tipo",attrs:{"src":_vm.flipCards[3] != _vm.isFlipping && _vm.tipoExibidoLinhas[3] == 'brasil'
                ? _vm.perfilBrasilDesligado
                : _vm.perfilCpfDesligado}}):_vm._e()]):_vm._e()],1),_c('Associacoes',{attrs:{"tipoExibido":_vm.tipoExibidoLinhas[3],"resultados":_vm.resultados}})],1),(!_vm.gerandoPdf)?_c('div',{staticClass:"verso"},[_c('div',{class:['box-titulo', _vm.tipoExibidoLinhas[3]],attrs:{"id":"BlocoSonar-associacoes-verso"}},[_c('img',{staticClass:"img-titulo assoc",attrs:{"src":_vm.iconAssoc}}),_c('span',{staticClass:"titulo-texto assoc"},[_vm._v(" Associações ")]),(!_vm.isFlipping)?_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(
              _vm.flipCards[3] != _vm.isFlipping &&
              (_vm.tipoExibidoLinhas[3] == 'brasil' ||
                _vm.tipoExibidoLinhas[3] == 'documento')
            )?_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.flipCards[3] != _vm.isFlipping),expression:"flipCards[3] != isFlipping"}],key:'icon' + _vm.tipoExibidoLinhas[3],staticClass:"img-tipo",attrs:{"src":_vm.flipCards[3] != _vm.isFlipping && _vm.tipoExibidoLinhas[3] == 'brasil'
                ? _vm.perfilBrasilDesligado
                : _vm.perfilCpfDesligado}}):_vm._e()]):_vm._e()],1),_c('Associacoes',{attrs:{"tipoExibido":_vm.tipoExibidoLinhas[3],"resultados":_vm.resultados}})],1):_vm._e()]),_vm._l((_vm.tooltips),function(tt,i){return _c('div',{key:i},[(_vm.exibirTootipsConsultas)?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":tt.target + '-frente',"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[_c('div',{domProps:{"innerHTML":_vm._s(tt.texto)}})]):_vm._e(),(_vm.exibirTootipsConsultas)?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":tt.target + '-verso',"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[_c('div',{domProps:{"innerHTML":_vm._s(tt.texto)}})]):_vm._e()],1)})],2):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"titulo-texto genero"},[_vm._v(" Gênero "),_c('br'),_vm._v(" Inferido ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"titulo-texto genero"},[_vm._v(" Gênero "),_c('br'),_vm._v(" Inferido ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"titulo-texto momento"},[_vm._v(" Provável "),_c('br'),_vm._v(" Momento de Vida ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"titulo-texto momento"},[_vm._v(" Provável "),_c('br'),_vm._v(" Momento de Vida ")])
}]

export { render, staticRenderFns }