<template>
  <div class="box-card">
    <div
      class="card-persona"
      :class="{ flipped: !cardPersona }"
      @click="flipPersona()"
    >
      <div
        class="frente"
        :style="{
          'background-image': 'url(' + cardSelecionado.imgFrente + ')',
        }"
        id="BlocoSonar-card-frente"
      >
        <span class="texto codigo" :style="{ color: cardSelecionado.cor }">
          {{ cardSelecionado.codigo }}
        </span>
        <span class="texto titulo">
          {{ cardSelecionado.titulo }}
        </span>
        <span class="texto persona" :style="{ color: cardSelecionado.cor }">
          {{ cardSelecionado.persona }}
        </span>
      </div>
      <div
        class="verso"
        :style="{
          'background-image': 'url(' + cardSelecionado.imgVerso + ')',
        }"
        id="BlocoSonar-card-verso"
        v-if="gerandoPdfEfetivo"
      >
        <span class="texto descricao">
          {{ cardSelecionado.descricao }}
        </span>
        <span class="texto subtitulo" :style="{ color: cardSelecionado.cor }">
          Representatividade da Persona no {{ tipo }}
        </span>
        <span class="texto valor">
          {{ representatividade }}
        </span>
      </div>
    </div>
    <div v-for="(tt, i) in tooltips" :key="i">
      <b-tooltip
      v-if="exibirTootipsConsultas"
        :target="tt.target"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        <div v-html="tt.texto"></div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";

export default {
  name: "BlocoPFSonarCard",

  components: {},

  props: {
    cardPersona: Boolean,
    cardSelecionado: Object,
    gerandoPdf: Boolean,
    resultados: Object,
    tipoExibido: String,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {
      setaCard: "/img/selecao-produtos/Seta-Abrir_banner.png",
    };
  },

  watch: {},

  methods: {
    flipPersona() {
      this.$emit("flipPersona");
    },
  },

  created() {},

  computed: {
    tooltips() {
      return listaTooltips.blocoSonarCard;
    },

    gerandoPdfEfetivo() {
      if (this.cardPersona && this.gerandoPdf) {
        return false;
      } else if (!this.cardPersona && this.gerandoPdf) {
        return true;
      } else {
        return true;
      }
    },

    tipo() {
      if (this.tipoExibido == "municipio") {
        return "município";
      } else {
        return "Brasil";
      }
    },

    representatividade() {
      if (this.tipoExibido == "municipio") {
        var atributo = "bk_111_001_num_municipio_persona_representatividade";
      } else {
        var atributo = "bk_111_001_num_brasil_persona_representatividade";
      }
      return this.resultados[atributo] != "-"
        ? this.resultados[atributo] + "%"
        : this.resultados[atributo];
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.box-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.card-persona {
  color: inherit;
  cursor: pointer;
  height: 100%;
  width: 100%;
  margin: 5px 0 0 0;
  border-radius: 25px;
  perspective: 1000px;
  position: relative;
}

.frente,
.verso {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
  position: absolute;
  height: 100%;
  width: 100%;
  max-height: 410px;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: ease-in-out 600ms;
  cursor: pointer;
}

.frente {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
}

.card-persona.flipped .frente {
  transform: rotateY(180deg);
}

.verso {
  transform: rotateY(-180deg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.card-persona.flipped .verso {
  transform: rotateY(0deg);
}

.texto {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  width: 100%;
  font-weight: 600;
}
.codigo {
  font-size: 36px;
  font-weight: 700;
  line-height: 12px;
  height: 52px;
  top: 283px;
}
.titulo {
  font-size: 17px;
  line-height: 17px;
  height: 56px;
  top: 167px;
  color: white;
  padding: 0px 10px;

}
.persona {
  font-size: 14px;
  line-height: 14px;
  height: 38px;
  top: 234px;
  align-items: flex-start;
  width: 95%;
}
.descricao {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  height: 210px;
  top: 85px;
  padding: 0px 10px;
  color: #212529;
}
.subtitulo {
  font-size: 11px;
  line-height: 14px;
  top: 111px;
  width: 123px;
  right: 33px;
  font-weight: 700;
}

.valor {
  color: white;
  font-size: 11px;
  font-weight: 400;
  top: 80px;
  left: 71px;
  width: fit-content;
  height: 33px;
}
</style>

<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
