<template>
  <div class="content">
    <div class="icons">
      <button class="buttonSB" id="openClose2" @click="alteraBlocos()">
        <img class="icon-box" :src="iconOpenClose" />
      </button>
      <b-tooltip
        v-if="abreBloco && exibirTootipsConsultas"
        :target="'openClose2'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Fechar
      </b-tooltip>
      <b-tooltip
        v-if="!abreBloco && exibirTootipsConsultas"
        :target="'openClose2'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Abrir
      </b-tooltip>
      <button class="buttonSB" id="goTop2" @click="goTop">
        <img class="icon-box" :src="iconToTop" />
      </button>
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="'goTop2'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Voltar ao Topo
      </b-tooltip>
    </div>
    <div class="dropdown" :class="{ 'dropdown--open': abreBloco }">
      <HeaderBlocos :bloco="blocoPFAssociacoesPessoais" />

      <div
        class="body"
        :class="{ 'body--aberto': abreBloco }"
        v-if="Object.keys(this.resultados).length !== 0"
      >
        <div class="row-body">
          <div class="col sb-6">
            <span
              class="labelSB-title azul"
              :id="'BlocoPFAssociacoesPessoais-1'"
            >
              Relação com Benefícios Sociais
            </span>

            <div class="tableRow">
              <div class="head azul-claro">
                <div
                  v-for="(item, j) in cabecGeralBeneficios"
                  :key="'beneficios' + item"
                  class="cabecTab azul-claro"
                  :class="{
                    'sb-2': j == 0 || j == 3,
                    'sb-1': j != 0 && j != 3,
                    'sb-right': j != 3,
                  }"
                  :style="j === 3 ? { padding: '2px 30px' } : {}"
                  :id="'BlocoPFAssociacoesPessoais-tb1-' + j"
                >
                  {{ item }}
                </div>
              </div>
              <div class="body-table">
                <div class="linhas">
                  <div
                    v-for="(item, j) in 4"
                    :key="'resBenef' + item + j"
                    class="resultTab sb-1"
                    :id="'B7Tabelas3-tb1-linhas-'"
                    :class="{
                      'sb-2': j == 0 || j == 3,
                      'sb-1': j != 0 && j != 3,
                      'sb-right': j != 3,
                    }"
                  >
                    <template v-if="j == 0">
                      <Check
                        :valor="
                          resultadoTratado.bk_104_999_flag_possui_beneficio
                        "
                      />
                    </template>
                    <template v-if="j == 1">
                      {{ resultadoTratado.bk_104_999_num_beneficios_distintos }}
                    </template>
                    <template v-if="j == 2">
                      {{
                        resultadoTratado.bk_104_999_num_meses_primeira_parcela
                      }}
                    </template>
                    <template v-if="j == 3">
                      {{ resultadoTratado.bk_104_999_num_meses_ultima_parcela }}
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="tableRow">
              <div class="head azul-claro">
                <div
                  v-for="(item, j) in cabecDetalheBeneficios"
                  :key="item"
                  class="cabecTab azul-claro"
                  :class="{
                    'sb-2': j == 0 || j == 3,
                    'sb-1': j != 0 && j != 3,
                    'sb-right': j != 3,
                  }"
                  :style="j == 2 ? { padding: '2px 8px' } : {}"
                  :id="'BlocoPFAssociacoesPessoais-tb2-' + j"
                >
                  {{ item }}
                </div>
              </div>
              <div class="body-table">
                <div
                  class="linhas"
                  v-for="(res, k) in valorDetalheBeneficios"
                  :key="res"
                >
                  <div
                    v-for="(item, j) in 4"
                    :key="'resDet' + item + j + k"
                    class="resultTab sb-1"
                    :class="{
                      'sb-2': j == 0 || j == 3,
                      'sb-1': j != 0 && j != 3,
                      'sb-right': j != 3,
                      titulo: j == 0,
                    }"
                    :id="
                      j === 0 ? 'BlocoPFAssociacoesPessoais-tb2-line-' + k : ''
                    "
                  >
                    <template v-if="j == 0">
                      {{ res }}
                    </template>
                    <template v-if="j == 1">
                      <Check :valor="flagBeneficios[k]" />
                    </template>
                    <template v-if="j == 2">
                      {{
                        resultadoTratado[getResultadoDetalhesBeneficiosTempo(k)]
                      }}
                      <span style="margin-left: 3px">{{
                        legendaMeses(
                          resultadoTratado[
                            getResultadoDetalhesBeneficiosTempo(k)
                          ]
                        )
                      }}</span>
                    </template>
                    <template v-if="j == 3">
                      {{
                        formatarData(
                          resultadoTratado[
                            getResultadoDetalhesBeneficiosData(k)
                          ]
                        )
                      }}
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <span
              class="labelSB-title azul-escuro"
              :id="'BlocoPFAssociacoesPessoais-2'"
            >
              Pessoa Exposta Politicamente
            </span>
            <div class="tableRow">
              <div class="head azul-claro">
                <div
                  v-for="(item, j) in cabecDetalhesPEP"
                  :key="'pep' + item"
                  class="cabecTab azul-claro"
                  :class="{
                    'sb-2': j == 0 || j == 3,
                    'sb-1': j != 0 && j != 3,
                    'sb-right': j != 3,
                  }"
                  :style="j == 3 ? { padding: '2px 40px' } : {}"
                  :id="'BlocoPFAssociacoesPessoais-tb5-' + j"
                >
                  {{ item }}
                </div>
              </div>
              <div class="body-table">
                <div
                  class="linhas"
                  v-for="(res, k) in valorDetalhesPEP"
                  :key="res"
                >
                  <div
                    v-for="(item, j) in 4"
                    :key="'resPep' + item + k + j"
                    class="resultTab sb-1"
                    :class="{
                      'sb-2': j == 0 || j == 3,
                      'sb-1': j != 0 && j != 3,
                      'sb-right': j != 3,
                      titulo: j == 0,
                    }"
                    :id="
                      j === 0 ? 'BlocoPFAssociacoesPessoais-tb5-line-' + k : ''
                    "
                  >
                    <template v-if="j == 0">
                      {{ res }}
                    </template>
                    <template v-if="j == 1">
                      <Check
                        :valor="
                          getBoolean(resultadoTratado[getResultadoPossuiPEP(k)])
                        "
                      />
                    </template>
                    <template v-if="j == 2">
                      {{ resultadoTratado[getResultadoTempoPEP(k)] }}
                      <span style="margin-left: 3px">{{
                        legendaAnos(resultadoTratado[getResultadoTempoPEP(k)])
                      }}</span>
                    </template>
                    <template v-if="j == 3">
                      {{
                        formatarData(resultadoTratado[getResultadoDataPEP(k)])
                      }}
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col sb-6">
            <span
              class="labelSB-title verde"
              :id="'BlocoPFAssociacoesPessoais-3'"
            >
              Servidores e Aposentados
            </span>

            <div class="tableRow">
              <div class="head azul-claro">
                <div
                  v-for="(item, j) in cabecGeralServidores"
                  :key="'servidores' + item"
                  class="cabecTab azul-claro sb-2"
                  :class="{
                    'sb-right': j != 2,
                  }"
                  :style="j != 0 ? { padding: '2px 40px' } : {}"
                  :id="'BlocoPFAssociacoesPessoais-tb3-' + j"
                >
                  {{ item }}
                </div>
              </div>
              <div class="body-table">
                <div class="linhas">
                  <div
                    v-for="(item, j) in 3"
                    :key="'resServ' + item + j"
                    class="resultTab sb-2"
                    :id="'B7Tabelas3-tb1-linhas-'"
                    :class="{
                      'sb-right': j != 2,
                    }"
                  >
                    <template v-if="j == 0">
                      <Check
                        :valor="resultadoTratado.bk_107_999_flag_possui_relacao"
                      />
                    </template>
                    <template v-if="j == 1">
                      {{ resultadoTratado.bk_107_999_num_relacoes }}
                    </template>
                    <template v-if="j == 2"
                      >{{ resultadoTratado.bk_107_999_tmp_max_relacao_anos }}
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="tableRow">
              <div class="head azul-claro">
                <div
                  v-for="(item, j) in cabecDetalhesServidores"
                  :key="item"
                  class="cabecTab azul-claro"
                  :class="{
                    'sb-2': j == 0 || j == 3,
                    'sb-1': j != 0 && j != 3,
                    'sb-right': j != 3,
                  }"
                  :style="j == 3 ? { padding: '2px 40px' } : {}"
                  :id="'BlocoPFAssociacoesPessoais-tb4-' + j"
                >
                  {{ item }}
                </div>
              </div>
              <div class="body-table">
                <div
                  class="linhas"
                  v-for="(res, k) in valorDetalhesServidores"
                  :key="res"
                >
                  <div
                    v-for="(item, j) in 4"
                    :key="'resDetServ' + item + k + j"
                    class="resultTab sb-1"
                    :class="{
                      'sb-2': j == 0 || j == 3,
                      'sb-1': j != 0 && j != 3,
                      'sb-right': j != 3,
                      titulo: j == 0,
                    }"
                    :id="
                      j === 0 ? 'BlocoPFAssociacoesPessoais-tb4-line-' + k : ''
                    "
                  >
                    <template v-if="j == 0">
                      {{ res }}
                    </template>
                    <template v-if="j == 1">
                      <Check
                        :valor="
                          resultadoTratado[
                            getResultadoFlagDetalhesServidores(k)
                          ]
                        "
                      />
                    </template>
                    <template v-if="j == 2">
                      {{ resultadoTratado[getResultadoTempoServidores(k)] }}
                      <span style="margin-left: 3px">{{
                        legendaAnos(
                          resultadoTratado[getResultadoTempoServidores(k)]
                        )
                      }}</span>
                    </template>
                    <template v-if="j == 3">
                      {{
                        formatarData(
                          resultadoTratado[getResultadoDataServidores(k)]
                        )
                      }}
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-for="(tt, i) in tooltipsBloco2" :key="i">
          <b-tooltip
            v-if="exibirTootipsConsultas"
            :target="tt.target"
            triggers="hover"
            placement="bottom"
            variant="dark"
            class="tooltipTexts"
            boundary="window"
          >
            <div v-html="tt.texto"></div>
          </b-tooltip>
        </div>
      </div>
      <div v-if="Object.keys(this.resultados).length === 0 && msgErro == ''">
        <Loading class="spinner" />
      </div>
      <md-card
        v-if="Object.keys(this.resultados).length === 0 && msgErro != ''"
        class="conteudo-horizontal-centralizado"
      >
        <md-card-content>
          {{ msgErro }}
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { storeAlertasSuperBox } from "@/stores/superbox-alertas.js";
import { mapState, mapActions } from "pinia";
import HeaderBlocos from "@/components/SuperBox/HeaderBlocos.vue";

import Check from "@/components/SuperBox/Check.vue";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";
import Loading from "@/components/Loading";

export default {
  name: "BlocoPFAssociacoesPessoais",

  components: {
    Check,
    HeaderBlocos,
    Loading,
  },

  props: {
    listaBlocos: Array,
    resultados: Object,
    consultaErros: Object,
    exibirTootipsConsultas: Boolean,
  },
  // 104_001 - Auxilio Emergencial
  // 104_003 - PETI
  // 104_004 - GarantiaSafra
  // 104_005 - BPC
  // 104_006 - SeguroDefeso
  // 104_007 - AuxilioBrasil

  data() {
    return {
      iconToTop: "/img/superbox/icon-totop.png",
      abreBloco: false,

      cabecGeralBeneficios: [
        "Possui?",
        "Total Distintos",
        "Meses 1ª Parcela",
        "Meses Últ. Parcela",
      ],

      cabecDetalheBeneficios: [
        "Detalhamentos",
        "Possui?",
        "Tempo desde Últ. Recebimento",
        "Data do Último Recebimento",
      ],

      valorDetalheBeneficios: [
        "Benefício de Prestação Continuada (BPC)",
        "Auxílio Brasil",
        "Auxílio Emergencial",
        "Bolsa Família",
        "Programa de Erradicação do Trabalho Infantil (PETI)",
        "Garantia-Safra",
        "Seguro Defeso",
      ],

      cabecGeralServidores: [
        "Possuí Relação?",
        "Total de Vínculos",
        "Tempo Máx. Meses",
      ],

      cabecDetalhesServidores: [
        "Listagem de Servidores",
        "Possui?",
        "Tempo desde Data Início",
        "Data Início Vínculo",
      ],

      valorDetalhesServidores: [
        "Aposentados SIAPE",
        "Servidores SIAPE",
        "Reserva Reforma Militares",
        "Associação Militar",
        "Servidores BACEN",
        "Aposentados BACEN",
      ],

      cabecDetalhesPEP: [
        "Detalhamentos",
        "Possui?",
        "Tempo desde Data Início",
        "Data Início Vínculo",
      ],

      valorDetalhesPEP: ["Associação PEP", "Atividade PEP"],
    };
  },

  watch: {
    resultados(newValue, oldValue) {
      if (Object.keys(newValue).length !== 0) {
        this.atualizaAlertas();
      }
    },
  },

  methods: {
    atualizaAlertas() {
      if (Object.keys(this.resultados).length !== 0) {
        for (let key in this.resultados) {
          if (
            key.startsWith("alertas_gerais_") &&
            this.resultados[key] === true
          ) {
            const idAlerta = key.replace("alertas_gerais_", "");
            this.ativaAlerta(idAlerta);
          }
        }
      }
    },

    getBoolean(valor) {
      if (typeof valor == "boolean") {
        return valor;
      } else {
        if (valor == 1) {
          return true;
        } else if (valor == 0) {
          return false;
        }
      }
    },

    getResultadoDetalhesBeneficiosFlag(i) {
      return [
        "bk_104_005_qtde_exatos",
        "bk_104_007_qtde_exatos",
        "bk_104_001_qtde_exatos",
        "bolsa_familia_qtde_exatos",
        "bk_104_003_qtde_exatos",
        "bk_104_004_qtde_exatos",
        "bk_104_006_qtde_exatos",
      ][i];
    },
    getResultadoDetalhesBeneficiosData(i) {
      return [
        "bk_104_005_data_ultimo_registro_exatos",
        "bk_104_007_data_ultimo_registro_exatos",
        "bk_104_001_data_ultimo_registro_exatos",
        "bolsa_familia_data_ultimo_registro_exatos",
        "bk_104_003_data_ultimo_registro_exatos",
        "bk_104_004_data_ultimo_registro_exatos",
        "bk_104_006_data_ultimo_registro_exatos",
      ][i];
    },
    getResultadoDetalhesBeneficiosTempo(i) {
      return [
        "bk_104_005_tmp_m_dt_ultimo_registro_exatos",
        "bk_104_007_tmp_m_dt_ultimo_registro_exatos",
        "bk_104_001_tmp_m_dt_ultimo_registro_exatos",
        "bolsa_familia_tmp_m_dt_ultimo_registro_exatos",
        "bk_104_003_tmp_m_dt_ultimo_registro_exatos",
        "bk_104_004_tmp_m_dt_ultimo_registro_exatos",
        "bk_104_006_tmp_m_dt_ultimo_registro_exatos",
      ][i];
    },
    getResultadoFlagDetalhesServidores(i) {
      return [
        "bk_107_001_ind_associacao_aposentados_siape",
        "bk_107_001_ind_associacao_servidores_siape",
        "bk_107_001_ind_associacao_reserva_reforma_militares",
        "bk_107_001_ind_associacao_militar",
        "bk_107_001_ind_associacao_servidores_bacen",
        "bk_107_001_ind_associacao_aposentados_bacen",
      ][i];
    },
    getResultadoDataServidores(i) {
      return [
        "bk_107_007_aposentados_siape_data_ingresso_orgao",
        "bk_107_003_servidores_siape_data_ingresso_orgao",
        "bk_107_008_reserva_reforma_militares_data_ingresso_orgao",
        "bk_107_004_servidores_militares_data_ingresso_orgao",
        "bk_107_002_servidores_bacen_data_ingresso_orgao",
        "bk_107_006_aposentados_bacen_data_ingresso_orgao",
      ][i];
    },
    getResultadoTempoServidores(i) {
      return [
        "bk_107_007_aposentados_siape_tmp_a_dt_ingresso_orgao",
        "bk_107_003_servidores_siape_tmp_a_dt_ingresso_orgao",
        "bk_107_008_reserva_reforma_militares_tmp_a_dt_ingresso_orgao",
        "bk_107_004_servidores_militares_tmp_a_dt_ingresso_orgao",
        "bk_107_002_servidores_bacen_tmp_a_dt_ingresso_orgao",
        "bk_107_006_aposentados_bacen_tmp_a_dt_ingresso_orgao",
      ][i];
    },
    getResultadoPossuiPEP(i) {
      return ["alertas_gerais_perfil_pep", "bk_106_001_ind_ativo"][i];
    },
    getResultadoTempoPEP(i) {
      return [
        "bk_106_001_tmp_a_dt_inicio_associacao",
        "bk_106_001_tmp_a_dt_inicio_exercicio",
      ][i];
    },
    getResultadoDataPEP(i) {
      return [
        "bk_106_001_dt_inicio_associacao",
        "bk_106_001_dt_inicio_exercicio",
      ][i];
    },

    formatarData(dataString) {
      if (dataString != null && dataString != "" && dataString != "-") {
        const data = new Date(dataString);
        const ano = data.getFullYear();
        const mes = (data.getMonth() + 1).toString().padStart(2, "0");
        const dia = data.getDate().toString().padStart(2, "0");

        return `${dia}/${mes}/${ano}`;
      } else {
        return dataString;
      }
    },

    legendaMeses(valor) {
      var texto;
      if (valor != "-" && valor != null) {
        if (valor == 1) {
          texto = " mes";
        } else {
          texto = " meses";
        }
      } else {
        texto = "";
      }
      return texto;
    },
    legendaAnos(valor) {
      var texto;
      if (valor != "-" && valor != null) {
        if (valor == 1) {
          texto = " ano";
        } else {
          texto = " anos";
        }
      } else {
        texto = "";
      }
      return texto;
    },

    goTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    alteraBlocos() {
      this.abreBloco = !this.abreBloco;
      this.alteraBloco(this.abreBloco, 0);
    },

    abreEsteBloco() {
      this.abreBloco = true;
    },

    // fechaEsteBloco() {
    //   this.abreBloco = true;
    // },
    ...mapActions(blocosSuperBoxStore, ["alteraBloco"]),
    ...mapActions(storeAlertasSuperBox, ["ativaAlerta"]),
  },

  created() {},

  computed: {
    resultadoTratado() {
      const objetoCopiado = { ...this.resultados };

      for (const chave in objetoCopiado) {
        if (
          objetoCopiado[chave] === null &&
          !chave.includes("flag") &&
          !chave.includes("_ind_")
        ) {
          objetoCopiado[chave] = "-";
        }
      }
      return objetoCopiado;
    },

    flagBeneficios() {
      return Array.from({ length: 7 }, (_, i) => {
        const resultado =
          this.resultadoTratado[this.getResultadoDetalhesBeneficiosFlag(i)];
        if (
          resultado != 1 &&
          this.resultadoTratado.bk_104_999_flag_possui_beneficio === false
        ) {
          return null;
        }
        switch (resultado) {
          case 0:
            return false;
          case 1:
            return true;
          default:
            return null;
        }
      });
    },

    blocoPFAssociacoesPessoais() {
      return this.listaBlocos.find(
        (bloco) => bloco.descricao === "Associações Pessoais"
      );
    },

    msgErro() {
      const chave = this.blocoPFAssociacoesPessoais.box;
      if (this.consultaErros[chave] !== undefined) {
        return this.consultaErros[chave];
      } else {
        return "";
      }
    },

    tooltipsBloco2() {
      return listaTooltips.associacoesPessoais;
    },

    iconOpenClose() {
      if (this.abreBloco) {
        return "/img/superbox/icon-dropdown.png";
      } else {
        return "/img/superbox/icon-dropdown-abrir.png";
      }
    },

    ...mapState(blocosSuperBoxStore, {
      openBlocos: "openBlocos",
    }),
  },

  mounted() {
    this.atualizaAlertas();
  },
};
</script>

<style lang="scss" scoped>
.row-body {
  margin: 5px;
  display: flex;
  align-items: flex-start !important;
  gap: 5px;
}

.body-table {
  display: flex;
  flex-direction: column;
}

.linhas {
  display: flex;
  height: 45px;
}

.col {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  max-width: 473px;
}

.labelSB-title {
  font-size: 16px;
  color: white;
  background-color: #0070c0;
  cursor: default;
  font-weight: 400;
  border-radius: 5px;
  padding: 2px 5px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.head {
  display: flex;
  border-radius: 5px;
  height: 45px;
}

.tableRow {
  display: flex;
  flex-direction: column;
  margin: 0 0px 10px 0px;
  cursor: default;
}

.cabecTab {
  height: 45px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  color: #0070c0;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2px 5px;
}

.azul-titulo {
  background-color: #2f75b5;
  color: white;
}

.azul {
  background-color: #0070c0;
  color: white;
}

.azul-escuro {
  background-color: #374581;
  color: white;
}
.azul-claro {
  background-color: #d9e1f2;
  color: #363636;
}

.verde {
  background-color: #18817e;
  color: white;
}

.resultTab {
  height: 40px;
  margin: 5px 0px 0 0;
  border: solid 1px #0070c0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 2px 5px;

  &.titulo {
    justify-content: flex-start;
    padding-left: 5px;
  }
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
