<template>
  <div class="content">
    <div class="icons">
      <button class="buttonSB" id="openClose9" @click="alteraBlocos()">
        <img class="icon-box" :src="iconOpenClose" />
      </button>
      <b-tooltip
        v-if="abreBloco && exibirTootipsConsultas"
        :target="'openClose9'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Fechar
      </b-tooltip>
      <b-tooltip
        v-if="!abreBloco && exibirTootipsConsultas"
        :target="'openClose9'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Abrir
      </b-tooltip>
      <button class="buttonSB" id="goTop9" @click="goTop">
        <img class="icon-box" :src="iconToTop" />
      </button>
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="'goTop9'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Voltar ao Topo
      </b-tooltip>
    </div>
    <div class="dropdown" :class="{ 'dropdown--open': abreBloco }">
      <HeaderBlocos :bloco="blocoPFAlertas" />

      <div class="body" :class="{ 'body--aberto': abreBloco }">
        <div class="secRow">
          <B9TipoAlertasGerais
            :iconAlerta="iconAlertasGerais"
            :alertasTitulo="alertasGeraisTitulo"
            :abreBloco="abreBloco"
            :exibirTootipsConsultas="exibirTootipsConsultas"
            @recebeTipoAlerta="recebeTipoAlerta"
          />
        </div>
        <div class="secRow">
          <B9TipoAlerta
            :iconAlerta="iconAlertasAltaPrior"
            :alertasTitulo="alertasAltaPrTitulo"
            :tipoSelecionado="tipoSelecionado"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />
          <div class="linha-vertical"></div>
          <B9TipoAlerta
            :iconAlerta="iconAlertasBaixaPrior"
            :alertasTitulo="alertasBaixaPrTitulo"
            :tipoSelecionado="tipoSelecionado"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />
          <div class="linha-vertical"></div>
          <B9TipoAlerta
            :iconAlerta="iconAlertasPositivos"
            :alertasTitulo="alertasPositTitulo"
            :tipoSelecionado="tipoSelecionado"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />
        </div>
        <div class="secRow" style="justify-content: space-between">
          <div class="alertaCol">
            <B9ListaAlertas
              tipoAlerta="alta"
              :exibirTootipsConsultas="exibirTootipsConsultas"
              @scrollToBlock="scrollToBlock"
            />
          </div>
          <div class="alertaCol">
            <B9ListaAlertas
              tipoAlerta="baixa"
              :exibirTootipsConsultas="exibirTootipsConsultas"
              @scrollToBlock="scrollToBlock"
            />
          </div>
          <div class="alertaCol">
            <B9ListaAlertas
              tipoAlerta="positivo"
              :exibirTootipsConsultas="exibirTootipsConsultas"
              @scrollToBlock="scrollToBlock"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { mapState, mapActions } from "pinia";
import HeaderBlocos from "@/components/SuperBox/HeaderBlocos.vue";
import produtosSuperBox from "@/helpers/produtos_superbox.json";

import B9TipoAlerta from "@/components/SuperBox/B9TipoAlerta.vue";
import B9TipoAlertasGerais from "@/components/SuperBox/B9TipoAlertasGerais.vue";
import B9ListaAlertas from "@/components/SuperBox/B9ListaAlertas.vue";

export default {
  name: "BlocoPFAlertas",

  components: {
    HeaderBlocos,
    B9TipoAlerta,
    B9ListaAlertas,
    B9TipoAlertasGerais,
  },

  props: {
    listaBlocos: Array,
    openChild: Boolean,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {
      iconToTop: "/img/superbox/icon-totop.png",

      iconAlertasGerais: "/img/superbox/alertas-gerais/alertas-gerais.png",
      iconAlertasAltaPrior:
        "/img/superbox/alertas-gerais/alertas-alta-prioridade.png",
      iconAlertasBaixaPrior: "/img/superbox/alertas-gerais/alerta-amarelo.png",
      // iconAlertasBaixaPrior: "/img/superbox/alertas-gerais/alertas-baixa-prioridade.png",
      iconAlertasPositivos:
        "/img/superbox/alertas-gerais/alertas-positivos.png",
      alertasGeraisTitulo: "Total de Alertas Encontados",
      alertasAltaPrTitulo: "Alertas de Alta Prioridade",
      alertasBaixaPrTitulo: "Alertas de Baixa Prioridade",
      alertasPositTitulo: "Alertas Positivos",

      tipoAlerta: "",
      abreBloco: false,
      tipoSelecionado: "azul",
    };
  },

  methods: {
    // controlaBlocos() {
    //   if (this.openChild) {
    //     this.$emit('fechaBlocos')
    //   } else {
    //     this.$emit('abreBlocos')
    //   }
    // },

    recebeTipoAlerta(tipo) {
      this.tipoSelecionado = tipo;
    },

    goTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    alteraBlocos() {
      this.abreBloco = !this.abreBloco;
      this.alteraBloco(this.abreBloco, 0);
    },

    abreEsteBloco() {
      this.abreBloco = true;
    },

    scrollToBlock(bloco) {
      this.$emit("scrollToBlockAlerta", bloco);
    },

    // fechaEsteBloco() {
    //   this.abreBloco = true;
    // },
    ...mapActions(blocosSuperBoxStore, ["alteraBloco"]),
  },

  created() {},

  computed: {
    blocoPFAlertas() {
      return this.listaBlocos.find((bloco) => bloco.titulo === "Bloco 09");
    },

    iconOpenClose() {
      if (this.abreBloco) {
        return "/img/superbox/icon-dropdown.png";
      } else {
        return "/img/superbox/icon-dropdown-abrir.png";
      }
    },

    ...mapState(blocosSuperBoxStore, {
      openBlocos: "openBlocos",
    }),
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.content {
  margin-bottom: 0;
}
.secRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 20px 0px;
  align-items: flex-start;
  justify-content: space-evenly;
  font-size: 12px;
}

.linha-horiz {
  border-bottom: dashed 1px #0070c0;
  width: 100%;
  height: 70%;
  margin: 0 10px 0 10px;
}
.linha-vertical {
  height: 135px;
  align-self: center;
  margin: 0 20px 0 20px;
  border-left: dashed 1px #0070c0;
}

.alertaCol {
  display: flex;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
