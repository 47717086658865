<template>
  <div class="content">
    <div class="icons">
      <button class="buttonSB" id="openClose6" @click="alteraBlocos()">
        <img class="icon-box" :src="iconOpenClose" />
      </button>
      <b-tooltip
        v-if="abreBloco && exibirTootipsConsultas"
        :target="'openClose6'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Fechar
      </b-tooltip>
      <b-tooltip
        v-if="!abreBloco && exibirTootipsConsultas"
        :target="'openClose6'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Abrir
      </b-tooltip>
      <button class="buttonSB" id="goTop6" @click="goTop">
        <img class="icon-box" :src="iconToTop" />
      </button>
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="'goTop6'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Voltar ao Topo
      </b-tooltip>
    </div>
    <div class="dropdown" :class="{ 'dropdown--open': abreBloco }">
      <HeaderBlocos :bloco="blocoPFPassagens" />

      <div
        class="body"
        :class="{ 'body--aberto': abreBloco }"
        v-if="Object.keys(this.resultados).length !== 0"
      >
        <div class="row">
          <BlocoPFPassagensTabela
            :resultados="resultadoTratado"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />
        </div>
      </div>
    </div>
    <div v-if="Object.keys(this.resultados).length === 0 && msgErro == ''">
      <Loading class="spinner" />
    </div>
    <md-card
      v-if="Object.keys(this.resultados).length === 0 && msgErro != ''"
      class="conteudo-horizontal-centralizado"
    >
      <md-card-content>
        {{ msgErro }}
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { storeAlertasSuperBox } from "@/stores/superbox-alertas.js";
import { mapState, mapActions } from "pinia";
import HeaderBlocos from "@/components/SuperBox/HeaderBlocos.vue";
import BlocoPFPassagensTabela from "@/components/SuperBox/BlocoPFPassagensTabela.vue";
import Loading from "@/components/Loading";

import Check from "@/components/SuperBox/Check.vue";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";

export default {
  name: "BlocoPFPassagens",

  components: { BlocoPFPassagensTabela, HeaderBlocos, Loading },

  props: {
    listaBlocos: Array,
    openChild: Boolean,
    resultados: Object,
    consultaErros: Object,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {
      iconToTop: "/img/superbox/icon-totop.png",
      abreBloco: false,
    };
  },

  watch: {
    resultados(newValue, oldValue) {
      if (Object.keys(newValue).length !== 0) {
        this.atualizaAlertas();
      }
    },
  },

  methods: {
    atualizaAlertas() {
      if (Object.keys(this.resultados).length !== 0) {
        for (let key in this.resultados) {
          if (
            key.startsWith("alertas_gerais_") &&
            this.resultados[key] === true
          ) {
            const idAlerta = key.replace("alertas_gerais_", "");
            this.ativaAlerta(idAlerta);
          }
        }
      }
    },

    formatarData(data) {
      const [dia, mes, ano] = data.split("/");
      return `${ano}-${mes}-${dia}`;
    },

    goTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    alteraBlocos() {
      this.abreBloco = !this.abreBloco;
      this.alteraBloco(this.abreBloco, 0);
    },

    abreEsteBloco() {
      this.abreBloco = true;
    },

    ...mapActions(blocosSuperBoxStore, ["alteraBloco"]),
    ...mapActions(storeAlertasSuperBox, ["ativaAlerta"]),
  },

  created() {},

  computed: {
    blocoPFPassagens() {
      return this.listaBlocos.find(
        (bloco) => bloco.descricao === "Histórico de Passagens"
      );
    },

    msgErro() {
      const chave = this.blocoPFPassagens.box;
      if (this.consultaErros[chave] !== undefined) {
        return this.consultaErros[chave];
      } else {
        return "";
      }
    },

    resultadoTratado() {
      if (Object.keys(this.resultados).length !== 0) {
        const objetoCopiado = { ...this.resultados };
        for (const chave in objetoCopiado) {
          if (
            objetoCopiado[chave] === null &&
            !chave.includes("flag") &&
            !chave.includes("_ind_")
          ) {
            objetoCopiado[chave] = "-";
          }
        }
        return objetoCopiado;
      } else {
        return {};
      }
    },

    iconOpenClose() {
      if (this.abreBloco) {
        return "/img/superbox/icon-dropdown.png";
      } else {
        return "/img/superbox/icon-dropdown-abrir.png";
      }
    },

    ...mapState(blocosSuperBoxStore, {
      openBlocos: "openBlocos",
    }),
  },

  mounted() {
    this.atualizaAlertas();
  },
};
</script>

<style lang="scss" scoped>
.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 5px 0px;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
