var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"icons"},[_c('button',{staticClass:"buttonSB",attrs:{"id":"openClose2"},on:{"click":function($event){return _vm.alteraBlocos()}}},[_c('img',{staticClass:"icon-box",attrs:{"src":_vm.iconOpenClose}})]),(_vm.abreBloco && _vm.exibirTootipsConsultas)?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":'openClose2',"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[_vm._v(" Fechar ")]):_vm._e(),(!_vm.abreBloco && _vm.exibirTootipsConsultas)?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":'openClose2',"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[_vm._v(" Abrir ")]):_vm._e(),_c('button',{staticClass:"buttonSB",attrs:{"id":"goTop2"},on:{"click":_vm.goTop}},[_c('img',{staticClass:"icon-box",attrs:{"src":_vm.iconToTop}})]),(_vm.exibirTootipsConsultas)?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":'goTop2',"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[_vm._v(" Voltar ao Topo ")]):_vm._e()],1),_c('div',{staticClass:"dropdown",class:{ 'dropdown--open': _vm.abreBloco }},[_c('HeaderBlocos',{attrs:{"bloco":_vm.blocoPFAssociacoesPessoais}}),(Object.keys(this.resultados).length !== 0)?_c('div',{staticClass:"body",class:{ 'body--aberto': _vm.abreBloco }},[_c('div',{staticClass:"row-body"},[_c('div',{staticClass:"col sb-6"},[_c('span',{staticClass:"labelSB-title azul",attrs:{"id":'BlocoPFAssociacoesPessoais-1'}},[_vm._v(" Relação com Benefícios Sociais ")]),_c('div',{staticClass:"tableRow"},[_c('div',{staticClass:"head azul-claro"},_vm._l((_vm.cabecGeralBeneficios),function(item,j){return _c('div',{key:'beneficios' + item,staticClass:"cabecTab azul-claro",class:{
                  'sb-2': j == 0 || j == 3,
                  'sb-1': j != 0 && j != 3,
                  'sb-right': j != 3,
                },style:(j === 3 ? { padding: '2px 30px' } : {}),attrs:{"id":'BlocoPFAssociacoesPessoais-tb1-' + j}},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('div',{staticClass:"body-table"},[_c('div',{staticClass:"linhas"},_vm._l((4),function(item,j){return _c('div',{key:'resBenef' + item + j,staticClass:"resultTab sb-1",class:{
                    'sb-2': j == 0 || j == 3,
                    'sb-1': j != 0 && j != 3,
                    'sb-right': j != 3,
                  },attrs:{"id":'B7Tabelas3-tb1-linhas-'}},[(j == 0)?[_c('Check',{attrs:{"valor":_vm.resultadoTratado.bk_104_999_flag_possui_beneficio}})]:_vm._e(),(j == 1)?[_vm._v(" "+_vm._s(_vm.resultadoTratado.bk_104_999_num_beneficios_distintos)+" ")]:_vm._e(),(j == 2)?[_vm._v(" "+_vm._s(_vm.resultadoTratado.bk_104_999_num_meses_primeira_parcela)+" ")]:_vm._e(),(j == 3)?[_vm._v(" "+_vm._s(_vm.resultadoTratado.bk_104_999_num_meses_ultima_parcela)+" ")]:_vm._e()],2)}),0)])]),_c('div',{staticClass:"tableRow"},[_c('div',{staticClass:"head azul-claro"},_vm._l((_vm.cabecDetalheBeneficios),function(item,j){return _c('div',{key:item,staticClass:"cabecTab azul-claro",class:{
                  'sb-2': j == 0 || j == 3,
                  'sb-1': j != 0 && j != 3,
                  'sb-right': j != 3,
                },style:(j == 2 ? { padding: '2px 8px' } : {}),attrs:{"id":'BlocoPFAssociacoesPessoais-tb2-' + j}},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('div',{staticClass:"body-table"},_vm._l((_vm.valorDetalheBeneficios),function(res,k){return _c('div',{key:res,staticClass:"linhas"},_vm._l((4),function(item,j){return _c('div',{key:'resDet' + item + j + k,staticClass:"resultTab sb-1",class:{
                    'sb-2': j == 0 || j == 3,
                    'sb-1': j != 0 && j != 3,
                    'sb-right': j != 3,
                    titulo: j == 0,
                  },attrs:{"id":j === 0 ? 'BlocoPFAssociacoesPessoais-tb2-line-' + k : ''}},[(j == 0)?[_vm._v(" "+_vm._s(res)+" ")]:_vm._e(),(j == 1)?[_c('Check',{attrs:{"valor":_vm.flagBeneficios[k]}})]:_vm._e(),(j == 2)?[_vm._v(" "+_vm._s(_vm.resultadoTratado[_vm.getResultadoDetalhesBeneficiosTempo(k)])+" "),_c('span',{staticStyle:{"margin-left":"3px"}},[_vm._v(_vm._s(_vm.legendaMeses( _vm.resultadoTratado[ _vm.getResultadoDetalhesBeneficiosTempo(k) ] )))])]:_vm._e(),(j == 3)?[_vm._v(" "+_vm._s(_vm.formatarData( _vm.resultadoTratado[ _vm.getResultadoDetalhesBeneficiosData(k) ] ))+" ")]:_vm._e()],2)}),0)}),0)]),_c('span',{staticClass:"labelSB-title azul-escuro",attrs:{"id":'BlocoPFAssociacoesPessoais-2'}},[_vm._v(" Pessoa Exposta Politicamente ")]),_c('div',{staticClass:"tableRow"},[_c('div',{staticClass:"head azul-claro"},_vm._l((_vm.cabecDetalhesPEP),function(item,j){return _c('div',{key:'pep' + item,staticClass:"cabecTab azul-claro",class:{
                  'sb-2': j == 0 || j == 3,
                  'sb-1': j != 0 && j != 3,
                  'sb-right': j != 3,
                },style:(j == 3 ? { padding: '2px 40px' } : {}),attrs:{"id":'BlocoPFAssociacoesPessoais-tb5-' + j}},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('div',{staticClass:"body-table"},_vm._l((_vm.valorDetalhesPEP),function(res,k){return _c('div',{key:res,staticClass:"linhas"},_vm._l((4),function(item,j){return _c('div',{key:'resPep' + item + k + j,staticClass:"resultTab sb-1",class:{
                    'sb-2': j == 0 || j == 3,
                    'sb-1': j != 0 && j != 3,
                    'sb-right': j != 3,
                    titulo: j == 0,
                  },attrs:{"id":j === 0 ? 'BlocoPFAssociacoesPessoais-tb5-line-' + k : ''}},[(j == 0)?[_vm._v(" "+_vm._s(res)+" ")]:_vm._e(),(j == 1)?[_c('Check',{attrs:{"valor":_vm.getBoolean(_vm.resultadoTratado[_vm.getResultadoPossuiPEP(k)])}})]:_vm._e(),(j == 2)?[_vm._v(" "+_vm._s(_vm.resultadoTratado[_vm.getResultadoTempoPEP(k)])+" "),_c('span',{staticStyle:{"margin-left":"3px"}},[_vm._v(_vm._s(_vm.legendaAnos(_vm.resultadoTratado[_vm.getResultadoTempoPEP(k)])))])]:_vm._e(),(j == 3)?[_vm._v(" "+_vm._s(_vm.formatarData(_vm.resultadoTratado[_vm.getResultadoDataPEP(k)]))+" ")]:_vm._e()],2)}),0)}),0)])]),_c('div',{staticClass:"col sb-6"},[_c('span',{staticClass:"labelSB-title verde",attrs:{"id":'BlocoPFAssociacoesPessoais-3'}},[_vm._v(" Servidores e Aposentados ")]),_c('div',{staticClass:"tableRow"},[_c('div',{staticClass:"head azul-claro"},_vm._l((_vm.cabecGeralServidores),function(item,j){return _c('div',{key:'servidores' + item,staticClass:"cabecTab azul-claro sb-2",class:{
                  'sb-right': j != 2,
                },style:(j != 0 ? { padding: '2px 40px' } : {}),attrs:{"id":'BlocoPFAssociacoesPessoais-tb3-' + j}},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('div',{staticClass:"body-table"},[_c('div',{staticClass:"linhas"},_vm._l((3),function(item,j){return _c('div',{key:'resServ' + item + j,staticClass:"resultTab sb-2",class:{
                    'sb-right': j != 2,
                  },attrs:{"id":'B7Tabelas3-tb1-linhas-'}},[(j == 0)?[_c('Check',{attrs:{"valor":_vm.resultadoTratado.bk_107_999_flag_possui_relacao}})]:_vm._e(),(j == 1)?[_vm._v(" "+_vm._s(_vm.resultadoTratado.bk_107_999_num_relacoes)+" ")]:_vm._e(),(j == 2)?[_vm._v(_vm._s(_vm.resultadoTratado.bk_107_999_tmp_max_relacao_anos)+" ")]:_vm._e()],2)}),0)])]),_c('div',{staticClass:"tableRow"},[_c('div',{staticClass:"head azul-claro"},_vm._l((_vm.cabecDetalhesServidores),function(item,j){return _c('div',{key:item,staticClass:"cabecTab azul-claro",class:{
                  'sb-2': j == 0 || j == 3,
                  'sb-1': j != 0 && j != 3,
                  'sb-right': j != 3,
                },style:(j == 3 ? { padding: '2px 40px' } : {}),attrs:{"id":'BlocoPFAssociacoesPessoais-tb4-' + j}},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('div',{staticClass:"body-table"},_vm._l((_vm.valorDetalhesServidores),function(res,k){return _c('div',{key:res,staticClass:"linhas"},_vm._l((4),function(item,j){return _c('div',{key:'resDetServ' + item + k + j,staticClass:"resultTab sb-1",class:{
                    'sb-2': j == 0 || j == 3,
                    'sb-1': j != 0 && j != 3,
                    'sb-right': j != 3,
                    titulo: j == 0,
                  },attrs:{"id":j === 0 ? 'BlocoPFAssociacoesPessoais-tb4-line-' + k : ''}},[(j == 0)?[_vm._v(" "+_vm._s(res)+" ")]:_vm._e(),(j == 1)?[_c('Check',{attrs:{"valor":_vm.resultadoTratado[
                          _vm.getResultadoFlagDetalhesServidores(k)
                        ]}})]:_vm._e(),(j == 2)?[_vm._v(" "+_vm._s(_vm.resultadoTratado[_vm.getResultadoTempoServidores(k)])+" "),_c('span',{staticStyle:{"margin-left":"3px"}},[_vm._v(_vm._s(_vm.legendaAnos( _vm.resultadoTratado[_vm.getResultadoTempoServidores(k)] )))])]:_vm._e(),(j == 3)?[_vm._v(" "+_vm._s(_vm.formatarData( _vm.resultadoTratado[_vm.getResultadoDataServidores(k)] ))+" ")]:_vm._e()],2)}),0)}),0)])])]),_vm._l((_vm.tooltipsBloco2),function(tt,i){return _c('div',{key:i},[(_vm.exibirTootipsConsultas)?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":tt.target,"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[_c('div',{domProps:{"innerHTML":_vm._s(tt.texto)}})]):_vm._e()],1)})],2):_vm._e(),(Object.keys(this.resultados).length === 0 && _vm.msgErro == '')?_c('div',[_c('Loading',{staticClass:"spinner"})],1):_vm._e(),(Object.keys(this.resultados).length === 0 && _vm.msgErro != '')?_c('md-card',{staticClass:"conteudo-horizontal-centralizado"},[_c('md-card-content',[_vm._v(" "+_vm._s(_vm.msgErro)+" ")])],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }