<template>
  <div class="row-sonar sb-top" v-if="liberaTela">
    <div
      class="sonar-box sb-right2"
      style="width: 135px"
      :class="{ flipping: flipCards[0] }"
      @mousedown="handleMouseDown('0')"
      @mouseup="handleMouseUp('0')"
      @mouseleave="handleMouseLeave('0')"
    >
      <div class="frente">
        <div
          :class="['box-titulo', tipoExibidoLinhas[0]]"
          :id="'BlocoSonar-geracao-frente'"
        >
          <img :src="iconGeracao" class="img-titulo gerac" />
          <span class="titulo-texto gerac"> Geração </span>
          <transition name="fade" mode="out-in" v-if="isFlipping">
            <img
              :src="
                flipCards[0] != isFlipping && tipoExibidoLinhas[0] == 'brasil'
                  ? perfilBrasilDesligado
                  : perfilCpfDesligado
              "
              class="img-tipo"
              v-if="
                flipCards[0] != isFlipping &&
                (tipoExibidoLinhas[0] == 'brasil' ||
                  tipoExibidoLinhas[0] == 'documento')
              "
              :key="'icon' + tipoExibidoLinhas[0]"
              v-show="flipCards[0] != isFlipping"
            />
          </transition>
        </div>
        <Geracao
          :tipoExibido="tipoExibidoLinhas[0]"
          :resultados="resultados"
          :lado="'frente'"
        />
      </div>
      <div class="verso" v-if="!gerandoPdf">
        <div
          :class="['box-titulo', tipoExibidoLinhas[0]]"
          :id="'BlocoSonar-geracao-verso'"
        >
          <img :src="iconGeracao" class="img-titulo gerac" />

          <span class="titulo-texto gerac"> Geração </span>
          <transition name="fade" mode="out-in" v-if="!isFlipping">
            <img
              :src="
                flipCards[0] != isFlipping && tipoExibidoLinhas[0] == 'brasil'
                  ? perfilBrasilDesligado
                  : perfilCpfDesligado
              "
              class="img-tipo"
              v-if="
                flipCards[0] != isFlipping &&
                (tipoExibidoLinhas[0] == 'brasil' ||
                  tipoExibidoLinhas[0] == 'documento')
              "
              :key="'icon' + tipoExibidoLinhas[0]"
              v-show="flipCards[0] != isFlipping"
            />
          </transition>
        </div>
        <Geracao
          :tipoExibido="tipoExibidoLinhas[0]"
          :resultados="resultados"
          :lado="'verso'"
        />
      </div>
    </div>
    <div
      class="sonar-box sb-right2"
      style="width: 115px"
      :class="{ flipping: flipCards[1] }"
      @mousedown="handleMouseDown('1')"
      @mouseup="handleMouseUp('1')"
      @mouseleave="handleMouseLeave('1')"
    >
      <div class="frente">
        <div
          :class="['box-titulo', tipoExibidoLinhas[1]]"
          id="BlocoSonar-genero-frente"
        >
          <img :src="iconGenero" class="img-titulo genero" />
          <span class="titulo-texto genero">
            Gênero <br />
            Inferido
          </span>

          <transition name="fade" mode="out-in" v-if="isFlipping">
            <img
              :src="
                flipCards[1] != isFlipping && tipoExibidoLinhas[1] == 'brasil'
                  ? perfilBrasilDesligado
                  : perfilCpfDesligado
              "
              class="img-tipo"
              v-if="
                flipCards[1] != isFlipping &&
                (tipoExibidoLinhas[1] == 'brasil' ||
                  tipoExibidoLinhas[1] == 'documento')
              "
              :key="'icon' + tipoExibidoLinhas[1]"
              v-show="flipCards[1] != isFlipping"
            />
          </transition>
        </div>
        <GeneroInferido
          :tipoExibido="tipoExibidoLinhas[1]"
          :resultados="resultados"
        />
      </div>
      <div class="verso" v-if="!gerandoPdf">
        <div
          :class="['box-titulo', tipoExibidoLinhas[1]]"
          id="BlocoSonar-genero-verso"
        >
          <img :src="iconGenero" class="img-titulo genero" />
          <span class="titulo-texto genero">
            Gênero <br />
            Inferido
          </span>
          <transition name="fade" mode="out-in" v-if="!isFlipping">
            <img
              :src="
                flipCards[1] != isFlipping && tipoExibidoLinhas[1] == 'brasil'
                  ? perfilBrasilDesligado
                  : perfilCpfDesligado
              "
              class="img-tipo"
              v-if="
                flipCards[1] != isFlipping &&
                (tipoExibidoLinhas[1] == 'brasil' ||
                  tipoExibidoLinhas[1] == 'documento')
              "
              :key="'icon' + tipoExibidoLinhas[1]"
              v-show="flipCards[1] != isFlipping"
            />
          </transition>
        </div>
        <GeneroInferido
          :tipoExibido="tipoExibidoLinhas[1]"
          :resultados="resultados"
        />
      </div>
    </div>
    <div
      class="sonar-box sb-right2"
      style="width: 215px"
      :class="{ flipping: flipCards[2] }"
      @mousedown="handleMouseDown('2')"
      @mouseup="handleMouseUp('2')"
      @mouseleave="handleMouseLeave('2')"
    >
      <div class="frente">
        <div
          :class="['box-titulo', tipoExibidoLinhas[2]]"
          id="BlocoSonar-momento-frente"
        >
          <img :src="iconMomento" class="img-titulo momento" />
          <span class="titulo-texto momento">
            Provável <br />
            Momento de Vida
          </span>
          <transition name="fade" mode="out-in" v-if="isFlipping">
            <img
              :src="
                flipCards[2] != isFlipping && tipoExibidoLinhas[2] == 'brasil'
                  ? perfilBrasilDesligado
                  : perfilCpfDesligado
              "
              class="img-tipo"
              v-if="
                flipCards[2] != isFlipping &&
                (tipoExibidoLinhas[2] == 'brasil' ||
                  tipoExibidoLinhas[2] == 'documento')
              "
              :key="'icon' + tipoExibidoLinhas[2]"
              v-show="flipCards[2] != isFlipping"
            />
          </transition>
        </div>
        <MomentoVida
          :tipoExibido="tipoExibidoLinhas[2]"
          :resultados="resultados"
        />
      </div>
      <div class="verso" v-if="!gerandoPdf">
        <div
          :class="['box-titulo', tipoExibidoLinhas[2]]"
          id="BlocoSonar-momento-verso"
        >
          <img :src="iconMomento" class="img-titulo momento" />
          <span class="titulo-texto momento">
            Provável <br />
            Momento de Vida
          </span>
          <transition name="fade" mode="out-in" v-if="!isFlipping">
            <img
              :src="
                flipCards[2] != isFlipping && tipoExibidoLinhas[2] == 'brasil'
                  ? perfilBrasilDesligado
                  : perfilCpfDesligado
              "
              class="img-tipo"
              v-if="
                flipCards[2] != isFlipping &&
                (tipoExibidoLinhas[2] == 'brasil' ||
                  tipoExibidoLinhas[2] == 'documento')
              "
              :key="'icon' + tipoExibidoLinhas[2]"
              v-show="flipCards[2] != isFlipping"
            />
          </transition>
        </div>
        <MomentoVida
          :tipoExibido="tipoExibidoLinhas[2]"
          :resultados="resultados"
        />
      </div>
    </div>
    <div
      class="sonar-box sb-3"
      :class="{ flipping: flipCards[3] }"
      @mousedown="handleMouseDown('3')"
      @mouseup="handleMouseUp('3')"
      @mouseleave="handleMouseLeave('3')"
    >
      <div class="frente">
        <div
          :class="['box-titulo', tipoExibidoLinhas[3]]"
          id="BlocoSonar-associacoes-frente"
        >
          <img :src="iconAssoc" class="img-titulo assoc" />
          <span class="titulo-texto assoc"> Associações </span>

          <transition name="fade" mode="out-in" v-if="isFlipping">
            <img
              :src="
                flipCards[3] != isFlipping && tipoExibidoLinhas[3] == 'brasil'
                  ? perfilBrasilDesligado
                  : perfilCpfDesligado
              "
              class="img-tipo"
              v-if="
                flipCards[3] != isFlipping &&
                (tipoExibidoLinhas[3] == 'brasil' ||
                  tipoExibidoLinhas[3] == 'documento')
              "
              :key="'icon' + tipoExibidoLinhas[3]"
              v-show="flipCards[3] != isFlipping"
            />
          </transition>
        </div>
        <Associacoes
          :tipoExibido="tipoExibidoLinhas[3]"
          :resultados="resultados"
        />
      </div>
      <div class="verso" v-if="!gerandoPdf">
        <div
          :class="['box-titulo', tipoExibidoLinhas[3]]"
          id="BlocoSonar-associacoes-verso"
        >
          <img :src="iconAssoc" class="img-titulo assoc" />
          <span class="titulo-texto assoc"> Associações </span>

          <transition name="fade" mode="out-in" v-if="!isFlipping">
            <img
              :src="
                flipCards[3] != isFlipping && tipoExibidoLinhas[3] == 'brasil'
                  ? perfilBrasilDesligado
                  : perfilCpfDesligado
              "
              class="img-tipo"
              v-if="
                flipCards[3] != isFlipping &&
                (tipoExibidoLinhas[3] == 'brasil' ||
                  tipoExibidoLinhas[3] == 'documento')
              "
              :key="'icon' + tipoExibidoLinhas[3]"
              v-show="flipCards[3] != isFlipping"
            />
          </transition>
        </div>
        <Associacoes
          :tipoExibido="tipoExibidoLinhas[3]"
          :resultados="resultados"
        />
      </div>
    </div>
    <div v-for="(tt, i) in tooltips" :key="i">
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="tt.target + '-frente'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        <div v-html="tt.texto"></div>
      </b-tooltip>
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="tt.target + '-verso'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        <div v-html="tt.texto"></div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { mapState, mapActions } from "pinia";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";
import Geracao from "@/components/SuperBox/SonarCards/Geracao.vue";
import GeneroInferido from "@/components/SuperBox/SonarCards/GeneroInferido.vue";
import MomentoVida from "@/components/SuperBox/SonarCards/MomentoVida.vue";
import Associacoes from "@/components/SuperBox/SonarCards/Associacoes.vue";

export default {
  name: "BlocoPFSonarLinha1",

  components: { Geracao, GeneroInferido, MomentoVida, Associacoes },

  props: {
    tipoExibido: String,
    isFlipping: Boolean,
    isFading: Boolean,
    gerandoPdf: Boolean,
    resultados: Object,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {
      iconGeracao: "/img/superbox/bloco-sonar/Geração.png",
      iconGenero: "/img/superbox/bloco-sonar/Genero.png",
      iconMomento: "/img/superbox/bloco-sonar/ProvavelMomentodeVida.png",
      iconAssoc: "/img/superbox/bloco-sonar/Associações.png",

      perfilCpfDesligado: "/img/superbox/bloco-sonar/PerfilCPF-desligado.svg",
      perfilBrasilDesligado:
        "/img/superbox/bloco-sonar/PersonaBrasil-desligado.png",

      tipoExibidoLinhas: ["", "", "", ""],
      flipCards: [false, false, false, false],
      liberaTela: false,
    };
  },

  watch: {
    tipoExibido(newVal) {
      this.tipoExibidoLinhas = this.tipoExibidoLinhas.map(() => newVal);
    },

    isFlipping(newVal) {
      this.flipCards = this.flipCards.map(() => newVal);
    },
  },

  methods: {
    handleMouseDown(i) {
      this.flipCards[i] = !this.flipCards[i];
      this.$set(
        this.tipoExibidoLinhas,
        i,
        this.tipoExibidoLinhas[i] === "documento" ? "brasil" : "documento"
      );
    },

    handleMouseUp(i) {
      this.flipCards[i] = !this.flipCards[i];
      this.$set(this.tipoExibidoLinhas, i, this.tipoExibido);
    },

    handleMouseLeave(i) {
      if (this.flipCards[i] !== this.isFlipping) {
        this.flipCards[i] = !this.flipCards[i];
        this.$set(this.tipoExibidoLinhas, i, this.tipoExibido);
      }
    },
  },

  created() {},

  computed: {
    tooltips() {
      return listaTooltips.blocoSonar1;
    },
  },

  mounted() {
    this.tipoExibidoLinhas = this.tipoExibidoLinhas.map(() => this.tipoExibido);
    this.liberaTela = true;
  },
};
</script>

<style lang="scss" scoped>
.row-sonar {
  display: flex;
  height: 130px;
  width: 100%;
}

.sonar-box {
  text-align: center;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  perspective: 5000px;

  .box-titulo {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 35px;
    min-height: 35px;
    border-radius: 10px 10px 0 0;
    line-height: 14px;
    cursor: pointer;

    .titulo-texto {
      position: relative;
      &.gerac {
        right: 14px;
      }
      &.genero {
        right: 13px;
      }
      &.momento {
        right: 14px;
      }
      &.assoc {
        right: 18px;
      }
    }

    .img-titulo {
      width: 40px;
      position: relative;

      &.gerac {
        right: 24px;
        width: 28px;
      }
      &.genero {
        right: 18px;
        width: 26px;
      }

      &.momento {
        right: 40px;
        width: 30px;
      }
      &.assoc {
        right: 62px;
        width: 27px;
      }
    }

    .img-tipo {
      width: 25px;
      position: absolute;
      right: 5px;
      opacity: 0.6;
    }
  }
}
</style>

<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
