<template>
  <div class="content">
    <div class="icons">
      <button class="buttonSB" id="openClose4" @click="alteraBlocos()">
        <img class="icon-box" :src="iconOpenClose" />
      </button>
      <b-tooltip
        v-if="abreBloco && exibirTootipsConsultas"
        :target="'openClose4'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Fechar
      </b-tooltip>
      <b-tooltip
        v-if="!abreBloco && exibirTootipsConsultas"
        :target="'openClose4'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Abrir
      </b-tooltip>
      <button class="buttonSB" id="goTop4" @click="goTop">
        <img class="icon-box" :src="iconToTop" />
      </button>
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="'goTop4'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Voltar ao Topo
      </b-tooltip>
    </div>
    <div class="dropdown" :class="{ 'dropdown--open': abreBloco }">
      <HeaderBlocos :bloco="blocoPFSonar" />
      <div
        class="body"
        :class="{ 'body--aberto': abreBloco }"
        v-if="Object.keys(this.resultados).length !== 0"
      >
        <div class="row">
          <div
            class="titulo-perfil"
            :class="{ fading: isFading }"
            :id="'BlocoSonar-titulo-' + tipoExibido"
          >
            <span v-if="liberaPerfilCpf" class="titulo-texto">
              Características da Persona (CPF consultado)
            </span>
            <span v-if="liberaPerfilMunicipio" class="titulo-texto">
              Características da Persona (Município)
            </span>
            <span v-if="liberaPerfilBrasil" class="titulo-texto">
              Características da Persona (Brasil)
            </span>
          </div>
          <div class="perfil-box">
            <img
              :src="
                liberaPerfilMunicipio
                  ? perfilMunicipioLigado
                  : perfilMunicipioDesligado
              "
              id="BlocoSonar-tipo-municipio"
              class="icon-perfil"
              @click="alteraPerfil('municipio')"
            />
            <img
              :src="
                liberaPerfilBrasil ? perfilBrasilLigado : perfilBrasilDesligado
              "
              id="BlocoSonar-tipo-brasil"
              class="icon-perfil"
              @click="alteraPerfil('brasil')"
            />
            <img
              :src="liberaPerfilCpf ? perfilCpfLigado : perfilCpfDesligado"
              id="BlocoSonar-tipo-documento"
              class="icon-perfil"
              @click="alteraPerfil('documento')"
            />
          </div>
        </div>
        <div class="cols">
          <div class="col-sonar persona sb-right2" style="width: 211px">
            <BlocoPFSonarCard
              :cardPersona="cardPersona"
              :cardSelecionado="cardSelecionado"
              :gerandoPdf="gerandoPdf"
              :resultados="resultadoTratado"
              :tipoExibido="tipoExibido"
              :exibirTootipsConsultas="exibirTootipsConsultas"
              @flipPersona="flipPersona"
            />
            <span
              class="seta-box"
              :id="
                !liberaCarrossel
                  ? 'BlocoSonar-seta-abrir'
                  : 'BlocoSonar-seta-fechar'
              "
            >
              <img
                v-show="!liberaCarrossel || gerandoPdf"
                :src="setaCardOpen"
                class="seta-img frente"
                @click="liberaCarrossel = !liberaCarrossel"
              />
              <img
                v-show="liberaCarrossel && !gerandoPdf"
                :src="setaCardClose"
                class="seta-img verso"
                id="BlocoSonar-seta-fechar"
                @click="liberaCarrossel = !liberaCarrossel"
              />
            </span>
          </div>
          <div class="col-sonar sb-right" v-if="abreBloco">
            <BlocoPFSonarLinha1
              :tipoExibido="tipoExibido"
              :isFlipping="isFlipping"
              :isFading="isFading"
              :gerandoPdf="gerandoPdf"
              :resultados="resultadoTratado"
              :exibirTootipsConsultas="exibirTootipsConsultas"
            />
            <BlocoPFSonarLinha2
              :tipoExibido="tipoExibido"
              :isFlipping="isFlipping"
              :isFading="isFading"
              :gerandoPdf="gerandoPdf"
              :resultados="resultadoTratado"
              :exibirTootipsConsultas="exibirTootipsConsultas"
            />
            <BlocoPFSonarLinha3
              :tipoExibido="tipoExibido"
              :isFlipping="isFlipping"
              :isFading="isFading"
              :gerandoPdf="gerandoPdf"
              :resultados="resultadoTratado"
              :exibirTootipsConsultas="exibirTootipsConsultas"
            />
          </div>
        </div>
        <div class="carrossel" v-if="liberaCarrossel && !gerandoPdf">
          <CarrosselPerfis
            :listaPerfis="listaPerfis"
            :exibirTootipsConsultas="exibirTootipsConsultas"
            :firstCard="firstCard"
          />
        </div>
        <div v-for="(tt, i) in tooltips" :key="i">
          <b-tooltip
            v-if="exibirTootipsConsultas"
            :target="tt.target"
            triggers="hover"
            placement="bottom"
            variant="dark"
            class="tooltipTexts"
            boundary="viewport"
          >
            <div v-html="tt.texto"></div>
          </b-tooltip>
        </div>
      </div>
      <div v-if="Object.keys(this.resultados).length === 0 && msgErro == ''">
        <Loading class="spinner" />
      </div>
      <md-card
        v-if="Object.keys(this.resultados).length === 0 && msgErro != ''"
        class="conteudo-horizontal-centralizado"
      >
        <md-card-content>
          {{ msgErro }}
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { mapState, mapActions } from "pinia";
import HeaderBlocos from "@/components/SuperBox/HeaderBlocos.vue";
import CircleProgress from "@/components/Visuais/CircleProgress.vue";
import BlocoPFSonarLinha1 from "@/components/SuperBox/BlocoPFSonarLinha1.vue";
import BlocoPFSonarLinha2 from "@/components/SuperBox/BlocoPFSonarLinha2.vue";
import BlocoPFSonarLinha3 from "@/components/SuperBox/BlocoPFSonarLinha3.vue";
import BlocoPFSonarCard from "@/components/SuperBox/BlocoPFSonarCard.vue";
import CarrosselPerfis from "@/pages/Dashboard/CarrosselPerfis.vue";
import listaPerfisJson from "@/helpers/lista_perfis_sonar.json";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";
import Loading from "@/components/Loading";

export default {
  name: "BlocoPFSonar",

  components: {
    HeaderBlocos,
    BlocoPFSonarLinha1,
    BlocoPFSonarLinha2,
    BlocoPFSonarLinha3,
    CarrosselPerfis,
    BlocoPFSonarCard,
    Loading,
  },

  props: {
    listaBlocos: Array,
    openChild: Boolean,
    consultaErros: Object,
    gerandoPdf: Boolean,
    resultados: Object,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {
      iconToTop: "/img/superbox/icon-totop.png",
      abreBloco: false,
      tipoExibido: "documento",
      liberaPerfilCpf: true,
      liberaPerfilMunicipio: false,
      liberaPerfilBrasil: false,
      isFading: false,
      isFlipping: false,

      cardPersona: true,
      liberaCarrossel: false,

      perfilCpfLigado: "/img/superbox/bloco-sonar/PerfilCPF-Ligado.svg",
      perfilCpfDesligado: "/img/superbox/bloco-sonar/PerfilCPF-desligado.svg",
      perfilMunicipioLigado:
        "/img/superbox/bloco-sonar/PersonaMunicípio-ligado.svg",
      perfilMunicipioDesligado:
        "/img/superbox/bloco-sonar/PersonaMunicípio-desligado.svg",
      perfilBrasilLigado: "/img/superbox/bloco-sonar/PersonaBrasil-ligado.png",
      perfilBrasilDesligado:
        "/img/superbox/bloco-sonar/PersonaBrasil-desligado.png",

      setaCardOpen: "/img/superbox/bloco-sonar/seta-card-branco-aberta.png",
      setaCardClose: "/img/superbox/bloco-sonar/seta-card-branco-fechada.png",
    };
  },

  watch: {},

  methods: {
    alteraPerfil(valor) {
      if (valor == "documento" && !this.liberaPerfilCpf) {
        this.isFading = true;
        this.liberaPerfilCpf = true;
        this.liberaPerfilMunicipio = false;
        this.liberaPerfilBrasil = false;
        this.isFlipping = !this.isFlipping;
      } else if (valor == "municipio" && !this.liberaPerfilMunicipio) {
        this.isFading = true;
        this.liberaPerfilCpf = false;
        this.liberaPerfilMunicipio = true;
        this.liberaPerfilBrasil = false;
        this.isFlipping = !this.isFlipping;
      } else if (valor == "brasil" && !this.liberaPerfilBrasil) {
        this.isFading = true;
        this.liberaPerfilCpf = false;
        this.liberaPerfilMunicipio = false;
        this.liberaPerfilBrasil = true;
        this.isFlipping = !this.isFlipping;
      }

      setTimeout(() => {
        this.isFading = false;
      }, 800);

      this.tipoExibido = valor;
    },

    formatarData(data) {
      const [dia, mes, ano] = data.split("/");
      return `${ano}-${mes}-${dia}`;
    },

    goTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    alteraBlocos() {
      this.abreBloco = !this.abreBloco;
      this.alteraBloco(this.abreBloco, 0);
    },

    abreEsteBloco() {
      this.abreBloco = true;
    },

    flipPersona() {
      this.cardPersona = !this.cardPersona;
    },

    ...mapActions(blocosSuperBoxStore, ["alteraBloco"]),
  },

  created() {},

  computed: {
    listaPerfis() {
      return listaPerfisJson;
    },

    resultadoTratado() {
      const objetoCopiado = { ...this.resultados };

      for (const chave in objetoCopiado) {
        if (
          objetoCopiado[chave] === null &&
          !chave.includes("flag") &&
          !chave.includes("_ind_")
        ) {
          objetoCopiado[chave] = "-";
        }
      }
      return objetoCopiado;
    },

    tooltips() {
      const tooltipsIniciais = listaTooltips.blocoSonar0;

      const detalhesIniciais = tooltipsIniciais.filter((detalhe) => {
        return detalhe.target.startsWith("BlocoSonar-titulo");
      });

      const detalhesFiltrados = detalhesIniciais.filter((detalhe) => {
        return detalhe.target.endsWith(this.tipoExibido);
      });

      const tooltipsSemDetalhesIniciais = tooltipsIniciais.filter((tt) => {
        return !detalhesIniciais.includes(tt);
      });

      const tooltipsFiltradosPorCarrossel = tooltipsSemDetalhesIniciais.filter(
        (tt) => {
          if (!this.liberaCarrossel && tt.target === "BlocoSonar-seta-fechar") {
            return false;
          }
          if (this.liberaCarrossel && tt.target === "BlocoSonar-seta-abrir") {
            return false;
          }
          return true;
        }
      );

      return [...tooltipsFiltradosPorCarrossel, ...detalhesFiltrados];
    },

    cardSelecionado() {
      const atributo = this.resultadoTratado.boxpersuppf002_persona;
      var card = listaPerfisJson.find((perfil) => perfil.codigo == atributo);
      if (!card) {
        card = listaPerfisJson.find((perfil) => perfil.codigo == "J50");
      }
      return card;
    },

    firstCard() {
      const letraSelecionada = this.cardSelecionado.codigo.charAt(0);
      const objetosMesmaLetra = this.listaPerfis.filter(
        (obj) => obj.codigo.charAt(0) === letraSelecionada
      );

      if (objetosMesmaLetra.length === 1 && letraSelecionada == "J") {
        return 1;
      } else {
        const menorNumero = objetosMesmaLetra
          .reduce((prev, current) => {
            const numPrev = parseInt(prev.codigo.substring(1));
            const numCurrent = parseInt(current.codigo.substring(1));

            return numPrev < numCurrent ? prev : current;
          })
          .codigo.substring(1);

        return parseInt(menorNumero, 10) + 1;
      }
    },

    blocoPFSonar() {
      return this.listaBlocos.find(
        (bloco) => bloco.descricao == "Sonar Outbox"
      );
    },

    msgErro() {
      const chave = this.blocoPFSonar.box;
      if (this.consultaErros[chave] !== undefined) {
        return this.consultaErros[chave];
      } else {
        return "";
      }
    },

    iconOpenClose() {
      if (this.abreBloco) {
        return "/img/superbox/icon-dropdown.png";
      } else {
        return "/img/superbox/icon-dropdown-abrir.png";
      }
    },

    ...mapState(blocosSuperBoxStore, {
      openBlocos: "openBlocos",
    }),
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.cols {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 5px;
  height: 435px;
}

.col-sonar {
  display: flex;
  height: 100%;
  flex-direction: column;
  margin: 0;
  align-items: center;
  perspective: 1000px;

  &.persona {
    width: 211px;
  }
}

.card-persona {
  height: 100%;
  width: 100%;
  margin: 5px 0 0 0;
  border-radius: 25px;
  position: relative;
  backface-visibility: hidden;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card-persona.frente {
  background-image: url("/img/superbox/bloco-sonar/Card_Personas/GrupoA_Card1_Frente.png");
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotateY(0deg);
}

.card-persona.verso {
  background-image: url("/img/superbox/bloco-sonar/Card_Personas/GrupoA_Card1_Verso.png");
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotateY(180deg);
  position: absolute;
  top: 0;
  left: 0;
}

.col-sonar.card-flipped .card-persona.frente {
  transform: rotateY(-180deg);
}

.col-sonar.card-flipped .card-persona.verso {
  transform: rotateY(0deg);
}

.seta-box {
  height: 20px;
  margin: 2px 0;
}
.seta-img {
  width: 55px;
  cursor: pointer;
  position: relative;

  &.frente {
    bottom: 0px;
  }
  &.verso {
    transform: rotateY(180deg);
    bottom: 0px;
  }
}

.row {
  justify-content: flex-end;
  max-width: 950px;
  margin: 5px;
}

.perfil-box {
  display: flex;
  gap: 5px;
}

.titulo-perfil {
  width: 80%;
  display: flex;
  justify-content: center;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  cursor: default;

  &.fading {
    opacity: 0;
  }
  .titulo-texto {
    font-weight: 500;
    font-size: 20px;
    color: #4356a5;
  }
}

.icon-perfil {
  width: 25px;
  cursor: pointer;
}
</style>

<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
