<template>
  <div class="content">
    <div class="tabelaResultados-passagens" id="Passagens-tab">
      <div class="linha-cabecalho">
        <span class="cabecalho-pass vazio sb-right sb-2"></span>
        <span
          class="cabecalho-pass azul-claro text-center"
          :class="{
            'sb-1': indexSuperior != 1 || indexSuperior != 2,
            'sb-2': indexSuperior == 1 || indexSuperior == 2,
            'sb-right': indexSuperior != 7,
          }"
          :id="'BlocoPFPassagens-sup-' + indexSuperior"
          style="padding: 0 15px"
          v-for="(indiceSuperior, indexSuperior) in indicesSuperiores"
          :key="indexSuperior"
        >
          {{ indiceSuperior }}
        </span>
      </div>
      <div class="tabela-corpo">
        <div
          v-for="(resposta, indexResposta) in respostaPassagens"
          :key="indexResposta"
          class="linha-resultados"
        >
          <div
            class="cabecalho-resultados sb-2 sb-top sb-right text-left"
            :class="{
              'azul-escuro': indexResposta == 0,
              'azul-claro': indexResposta != 0,
            }"
          >
            <span
              class="cell"
              :id="'BlocoPFPassagens-lateral-' + indexResposta"
            >
              {{ indicesLaterais[indexResposta] }}
            </span>
          </div>
          <span class="text-result sb-1 sb-right text-center">
            <span class="cell flag">
              <check :valor="resposta.flagDeHistorico" />
            </span>
          </span>
          <span
            class="text-result text-center"
            :class="{
              'sb-1': indexValor != 0 || indexValor != 1,
              'sb-2': indexValor == 0 || indexValor == 1,
              'sb-right': indexValor != 6,
            }"
            v-for="(valor, indexValor) in resposta.outrosValores"
            :key="indexValor"
          >
            <span class="cell">
              {{ valor }}
              <span
                v-if="
                  valor != '-' &&
                  valor > 0 &&
                  valor != 1 &&
                  (indexValor == 0 || indexValor == 1)
                "
                style="margin-left: 3px"
              >
                meses
              </span>
              <span
                v-if="
                  valor != '-' &&
                  valor == 1 &&
                  (indexValor == 0 || indexValor == 1)
                "
              >
                mes
              </span>
            </span>
          </span>
          <span class="text-result sb-1 text-center">
            <span class="cell flag">
              <check :valor="resposta.flagDeAlerta" />
            </span>
          </span>
        </div>
      </div>
    </div>

    <div v-for="(tt, i) in tooltipsBloco6" :key="i">
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="tt.target"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        <div v-html="tt.texto"></div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { mapState, mapActions } from "pinia";
import HeaderBlocos from "@/components/SuperBox/HeaderBlocos.vue";
import Loading from "@/components/Loading";

import Check from "@/components/SuperBox/Check.vue";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";

export default {
  name: "BlocoPFPassagensTabela",

  components: { Check },

  props: {
    listaBlocos: Array,
    openChild: Boolean,
    resultados: Object,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {
      indicesSuperiores: [
        "Flag de Histórico",
        "Tempo desde a Primeira Passagem",
        "Tempo desde a Última Passagem",
        "Quantidade total de Passagens",
        "Qtd  Passagens Últ. 30 dias",
        "Qtd  Passagens Últ. 7 dias",
        "Qtd Passagem Últ. 24h",
        "Alerta Últ. 24h",
      ],
      indicesLaterais: [
        "Consolidado Geral",
        "Riscos e Outros",
        "Bancos e Financeiras",
        "Varejo",
        "Indústria",
        "Seguros",
        "Infraestrutura",
        "Saúde",
        "Educação",
        "Serviços",
      ],
      respostaPassagens: [
        {
          flagDeHistorico: this.resultados.bk_101_101_geral_qtde_05a > 0,
          outrosValores: [
            this.resultados.bk_101_101_geral_meses_primeira_passagem,
            this.resultados.bk_101_101_geral_meses_ultima_passagem,
            this.resultados.bk_101_101_geral_qtde_05a,
            this.resultados.bk_101_101_geral_qtde_30d,
            this.resultados.bk_101_101_geral_qtde_07d,
            this.resultados.bk_101_101_geral_qtde_24h,
          ],
          flagDeAlerta: this.getFlagAlerta("geral"),
        },
        {
          flagDeHistorico: this.resultados.bk_101_101_risco_qtde_05a > 0,
          outrosValores: [
            this.resultados.bk_101_101_risco_meses_primeira_passagem,
            this.resultados.bk_101_101_risco_meses_ultima_passagem,
            this.resultados.bk_101_101_risco_qtde_05a,
            this.resultados.bk_101_101_risco_qtde_30d,
            this.resultados.bk_101_101_risco_qtde_07d,
            this.resultados.bk_101_101_risco_qtde_24h,
          ],
          flagDeAlerta: this.getFlagAlerta("risco"),
        },
        {
          flagDeHistorico: this.resultados.bk_101_101_financeiro_qtde_05a > 0,
          outrosValores: [
            this.resultados.bk_101_101_financeiro_meses_primeira_passagem,
            this.resultados.bk_101_101_financeiro_meses_ultima_passagem,
            this.resultados.bk_101_101_financeiro_qtde_05a,
            this.resultados.bk_101_101_financeiro_qtde_30d,
            this.resultados.bk_101_101_financeiro_qtde_07d,
            this.resultados.bk_101_101_financeiro_qtde_24h,
          ],
          flagDeAlerta: this.getFlagAlerta("financeiro"),
        },
        {
          flagDeHistorico: this.resultados.bk_101_101_varejo_qtde_05a > 0,
          outrosValores: [
            this.resultados.bk_101_101_varejo_meses_primeira_passagem,
            this.resultados.bk_101_101_varejo_meses_ultima_passagem,
            this.resultados.bk_101_101_varejo_qtde_05a,
            this.resultados.bk_101_101_varejo_qtde_30d,
            this.resultados.bk_101_101_varejo_qtde_07d,
            this.resultados.bk_101_101_varejo_qtde_24h,
          ],
          flagDeAlerta: this.getFlagAlerta("varejo"),
        },

        {
          flagDeHistorico: this.resultados.bk_101_101_industria_qtde_05a > 0,
          outrosValores: [
            this.resultados.bk_101_101_industria_meses_primeira_passagem,
            this.resultados.bk_101_101_industria_meses_ultima_passagem,
            this.resultados.bk_101_101_industria_qtde_05a,
            this.resultados.bk_101_101_industria_qtde_30d,
            this.resultados.bk_101_101_industria_qtde_07d,
            this.resultados.bk_101_101_industria_qtde_24h,
          ],
          flagDeAlerta: this.getFlagAlerta("industria"),
        },
        {
          flagDeHistorico: this.resultados.bk_101_101_seguros_qtde_05a > 0,
          outrosValores: [
            this.resultados.bk_101_101_seguros_meses_primeira_passagem,
            this.resultados.bk_101_101_seguros_meses_ultima_passagem,
            this.resultados.bk_101_101_seguros_qtde_05a,
            this.resultados.bk_101_101_seguros_qtde_30d,
            this.resultados.bk_101_101_seguros_qtde_07d,
            this.resultados.bk_101_101_seguros_qtde_24h,
          ],
          flagDeAlerta: this.getFlagAlerta("seguros"),
        },
        {
          flagDeHistorico:
            this.resultados.bk_101_101_infraestrutura_qtde_05a > 0,
          outrosValores: [
            this.resultados.bk_101_101_infraestrutura_meses_primeira_passagem,
            this.resultados.bk_101_101_infraestrutura_meses_ultima_passagem,
            this.resultados.bk_101_101_infraestrutura_qtde_05a,
            this.resultados.bk_101_101_infraestrutura_qtde_30d,
            this.resultados.bk_101_101_infraestrutura_qtde_07d,
            this.resultados.bk_101_101_infraestrutura_qtde_24h,
          ],
          flagDeAlerta: this.getFlagAlerta("infraestrutura"),
        },
        {
          flagDeHistorico: this.resultados.bk_101_101_saude_qtde_05a > 0,
          outrosValores: [
            this.resultados.bk_101_101_saude_meses_primeira_passagem,
            this.resultados.bk_101_101_saude_meses_ultima_passagem,
            this.resultados.bk_101_101_saude_qtde_05a,
            this.resultados.bk_101_101_saude_qtde_30d,
            this.resultados.bk_101_101_saude_qtde_07d,
            this.resultados.bk_101_101_saude_qtde_24h,
          ],
          flagDeAlerta: this.getFlagAlerta("saude"),
        },
        {
          flagDeHistorico: this.resultados.bk_101_101_educacao_qtde_05a > 0,
          outrosValores: [
            this.resultados.bk_101_101_educacao_meses_primeira_passagem,
            this.resultados.bk_101_101_educacao_meses_ultima_passagem,
            this.resultados.bk_101_101_educacao_qtde_05a,
            this.resultados.bk_101_101_educacao_qtde_30d,
            this.resultados.bk_101_101_educacao_qtde_07d,
            this.resultados.bk_101_101_educacao_qtde_24h,
          ],
          flagDeAlerta: this.getFlagAlerta("educacao"),
        },
        {
          flagDeHistorico: this.resultados.bk_101_101_servicos_qtde_05a > 0,
          outrosValores: [
            this.resultados.bk_101_101_servicos_meses_primeira_passagem,
            this.resultados.bk_101_101_servicos_meses_ultima_passagem,
            this.resultados.bk_101_101_servicos_qtde_05a,
            this.resultados.bk_101_101_servicos_qtde_30d,
            this.resultados.bk_101_101_servicos_qtde_07d,
            this.resultados.bk_101_101_servicos_qtde_24h,
          ],
          flagDeAlerta: this.getFlagAlerta("servicos"),
        },
      ],
      iconToTop: "/img/superbox/icon-totop.png",
      abreBloco: false,
    };
  },

  methods: {
    formatarData(data) {
      const [dia, mes, ano] = data.split("/");
      return `${ano}-${mes}-${dia}`;
    },

    getFlagAlerta(keyPart) {
      const key = `bk_101_101_${keyPart}_qtde_24h`;
      const value = this.resultados[key];
      if (value === null || value === "-") {
        return null;
      }
      return value > 0;
    },

    ...mapActions(blocosSuperBoxStore, ["alteraBloco"]),
  },

  created() {},

  computed: {
    tooltipsBloco6() {
      return listaTooltips.bloco06;
    },

    ...mapState(blocosSuperBoxStore, {
      openBlocos: "openBlocos",
    }),
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.tabelaResultados-passagens {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.linha-cabecalho {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  max-width: 950px;
}

.linha-resultados {
  max-width: 950px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cabecalho-pass {
  height: 55px;
  padding: 2px 4px !important;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  cursor: default;
  font-weight: 400;

  &.flag {
    padding: 0px 5px !important;
  }
}

.cabecalho-resultados {
  height: 40px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #0070c0;
  border: none;
  cursor: default;

  .cell {
    border: none;
  }
}

.azul-escuro {
  background-color: #374581;
  color: white;
  border-radius: 5px;
}

.azul-claro {
  background-color: #d9e1f2;
  color: #363636 !important;
  border-radius: 5px;
}

.tabela-corpo {
  border: none !important;
}
.text-result {
  height: 40px;
  border: solid 1px #0070c0;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #363636;
  cursor: default;
  display: flex;
}
.cell {
  padding: 0 5px;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 37px;
  line-height: 18px;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
