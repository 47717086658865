var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content",class:{ '-vert': _vm.abreBloco }},[_c('div',{staticClass:"icons",class:{ '-vert': _vm.abreBloco }},[_c('button',{staticClass:"buttonSB",attrs:{"id":"openClose5"},on:{"click":function($event){return _vm.abrirChatPersonalizado('MapaRiscos')}}},[_c('span',{staticClass:"material-symbols-outlined icone-chat"},[_vm._v(" smart_toy ")])]),_c('button',{staticClass:"buttonSB",attrs:{"id":"openClose5"},on:{"click":function($event){return _vm.alteraBlocos()}}},[_c('img',{staticClass:"icon-box",attrs:{"src":_vm.iconOpenClose}})]),(_vm.abreBloco && _vm.exibirTootipsConsultas)?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":'openClose5',"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[_vm._v(" Fechar ")]):_vm._e(),(!_vm.abreBloco && _vm.exibirTootipsConsultas)?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":'openClose5',"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[_vm._v(" Abrir ")]):_vm._e(),_c('button',{staticClass:"buttonSB",attrs:{"id":"goTop5"},on:{"click":_vm.goTop}},[_c('img',{staticClass:"icon-box",attrs:{"src":_vm.iconToTop}})]),(_vm.exibirTootipsConsultas)?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":'goTop5',"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[_vm._v(" Voltar ao Topo ")]):_vm._e()],1),_c('div',{staticClass:"dropdown",class:{ '-vert dropdown--open': _vm.abreBloco }},[_c('HeaderBlocos',{attrs:{"bloco":_vm.blocoPFMapaRiscos,"abreBloco":_vm.abreBloco}}),(Object.keys(this.resultados).length !== 0)?_c('div',{staticClass:"body",class:{ '-vert body--aberto': _vm.abreBloco },attrs:{"id":"Mapa-riscos"}},[_c('div',{staticClass:"mapaGeral"},[_vm._l((_vm.mapaGeralRiscos),function(risco,i){return _c('div',{key:i,staticClass:"linhas-riscos"},[_c('div',{staticClass:"tipo sb-2 sb-right",style:({
              'background-color': risco.cor,
              color: i < 3 ? 'black' : 'white',
              'font-weight': i == 5 ? '700' : '400',
            }),attrs:{"id":'BlocoPFMapaRiscos-nome-' + i}},[_c('span',{staticClass:"box-icon"},[_c('img',{staticClass:"icon-tipo",attrs:{"src":risco.icone}})]),_c('p',{staticClass:"tipo-text"},[_vm._v(" "+_vm._s(risco.nome)+" ")])]),_c('div',{staticClass:"valor sb-1 sb-right",attrs:{"id":'BlocoPFMapaRiscos-valor-' + i + '-' + risco.valor}},[(risco.valor == 'P')?_c('span',{staticClass:"box-valor"},[_c('img',{staticClass:"icon-valor left",attrs:{"src":"/img/superbox/mapa-riscos-prat.png"}}),_c('img',{staticClass:"icon-valor",attrs:{"src":"/img/superbox/mapa-riscos-cust-cinza.png"}})]):_vm._e(),(risco.valor == 'C')?_c('span',{staticClass:"box-valor"},[_c('img',{staticClass:"icon-valor left",attrs:{"src":"/img/superbox/mapa-riscos-prat-cinza.png"}}),_c('img',{staticClass:"icon-valor",attrs:{"src":"/img/superbox/mapa-riscos-cust.png"}})]):_vm._e()]),_c('div',{staticClass:"score sb-1",class:{
              sonar: i == 5,
            }},[_vm._v(" "+_vm._s(risco.score)+" ")])])}),_c('div',{staticClass:"saibaMais sb-4"},[_vm._m(0),_c('button',{staticClass:"btn-contato",on:{"click":_vm.goContato}},[_vm._v("Contato")])])],2),_c('div',{staticClass:"grafBox"},[(this.liberaChart)?_c('div',{staticClass:"graf"},[_c('RadarChart',{attrs:{"data":_vm.mapaGeralRiscos,"score":_vm.resultados}})],1):_vm._e(),_c('div',{staticClass:"detalhes"},[_c('div',{staticClass:"ranking"},[_c('span',{staticClass:"rankTexto text-center",class:_vm.getRankColorClass()},[_vm._v(" "+_vm._s(_vm.rankSonar)+" ")]),_c('span',{staticClass:"rankDetalhe text-center"},[_vm._v(" "+_vm._s(_vm.textoRankSonar)+" ")])]),_c('div',{staticClass:"texto"},[_vm._v(" "+_vm._s(_vm.detalhesSonar)+" ")])])])]):_vm._e(),(Object.keys(this.resultados).length === 0 && _vm.msgErro == '')?_c('div',[_c('Loading',{staticClass:"spinner"})],1):_vm._e(),(Object.keys(this.resultados).length === 0 && _vm.msgErro != '')?_c('md-card',{staticClass:"conteudo-horizontal-centralizado"},[_c('md-card-content',[_vm._v(" "+_vm._s(_vm.msgErro)+" ")])],1):_vm._e()],1),(_vm.abreBloco && _vm.liberaChart)?_c('div',_vm._l((_vm.tooltipsBloco5Filtrado),function(tt,i){return _c('div',{key:i},[(_vm.exibirTootipsConsultas)?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":tt.target,"triggers":"hover","placement":"left","variant":"dark","boundary":"window"}},[_c('div',{domProps:{"innerHTML":_vm._s(tt.texto)}}),(_vm.mapaGeralRiscos)?_c('div',_vm._l((_vm.mapaGeralRiscos),function(item,j){return _c('div',{key:j},[(
                tt.target.includes('BlocoPFMapaRiscos-valor-' + j) &&
                _vm.mapaGeralRiscos[j].codigo != '' &&
                _vm.mapaGeralRiscos[j].codigo != undefined
              )?_c('span',[_c('br'),_vm._v(" Código do modelo: "+_vm._s(_vm.mapaGeralRiscos[j].codigo)+" ")]):_vm._e()])}),0):_vm._e()]):_vm._e()],1)}),0):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"saibaMaix-text"},[_vm._v("Impulsione sua decisão com uma "),_c('strong',{staticClass:"strong"},[_vm._v(" CUSTOMIZAÇÃO ")])])
}]

export { render, staticRenderFns }