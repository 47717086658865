<template>
  <div class="content">
    <div class="icons">
      <button class="buttonSB" id="openClose7" @click="alteraBlocos()">
        <img class="icon-box" :src="iconOpenClose" />
      </button>
      <b-tooltip
        v-if="abreBloco"
        :target="'openClose7'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Fechar
      </b-tooltip>
      <b-tooltip
        v-if="!abreBloco"
        :target="'openClose7'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Abrir
      </b-tooltip>
      <button class="buttonSB" id="goTop7" @click="goTop">
        <img class="icon-box" :src="iconToTop" />
      </button>
      <b-tooltip
        :target="'goTop7'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Voltar ao Topo
      </b-tooltip>
    </div>
    <div class="dropdown" :class="{ 'dropdown--open': abreBloco }">
      <HeaderBlocos :bloco="blocoPFConexoesEmpresas" />

      <div
        class="body"
        :class="{ 'body--aberto': abreBloco }"
        v-if="Object.keys(this.resultados).length !== 0"
      >
        <div class="rowSec" style="margin-top: 5px">
          <B7Tabelas1
            :resultados="resultadoTratado"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />
        </div>
        <div class="rowSec">
          <B7Tabelas2
            :resultados="resultadoTratado"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />
        </div>
        <div class="rowSec">
          <B7Tabelas3
            :resultados="resultadoTratado"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />
        </div>
        <div class="rowSec">
          <B7Tabelas4
            :resultados="resultadoTratado"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />
        </div>
        <div class="rowSec">
          <B7Mapa
            v-if="this.abreBloco"
            :abreBloco="abreBloco"
            :codigo_agrupador="codigo_agrupador"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />
        </div>
        <div class="rowSec">
          <B7Ranking
            :resultados="resultadoTratado"
            :codigo_agrupador="codigo_agrupador"
            :resultadosFaturamento="resultadosFaturamento"
            :gerandoPdf="gerandoPdf"
            :exibirTootipsConsultas="exibirTootipsConsultas"
            :valorDataRef="valorDataRef"
          />
        </div>
      </div>
      <div v-if="Object.keys(this.resultados).length === 0 && msgErro == ''">
        <Loading class="spinner" />
      </div>
      <md-card
        v-if="Object.keys(this.resultados).length === 0 && msgErro != ''"
        class="conteudo-horizontal-centralizado"
      >
        <md-card-content>
          {{ msgErro }}
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { storeAlertasSuperBox } from "@/stores/superbox-alertas.js";
import { mapState, mapActions } from "pinia";
import HeaderBlocos from "@/components/SuperBox/HeaderBlocos.vue";

import B7Tabelas1 from "@/components/SuperBox/B7Tabelas1.vue";
import B7Tabelas2 from "@/components/SuperBox/B7Tabelas2.vue";
import B7Tabelas3 from "@/components/SuperBox/B7Tabelas3.vue";
import B7Tabelas4 from "@/components/SuperBox/B7Tabelas4.vue";
import B7Mapa from "@/components/SuperBox/B7Mapa.vue";
import B7Ranking from "@/components/SuperBox/B7Ranking.vue";
import Loading from "@/components/Loading";

export default {
  name: "BlocoPFConexoesEmpresas",

  components: {
    HeaderBlocos,
    B7Tabelas1,
    B7Tabelas2,
    B7Tabelas3,
    B7Tabelas4,
    B7Mapa,
    B7Ranking,
    Loading,
  },

  props: {
    listaBlocos: Array,
    resultadosFaturamento: Array,
    openChild: Boolean,
    codigo_agrupador: String,
    resultados: Object,
    consultaErros: Object,
    gerandoPdf: Boolean,
    exibirTootipsConsultas: Boolean,
    valorDataRef: String,
  },

  data() {
    return {
      iconToTop: "/img/superbox/icon-totop.png",
      abreBloco: false,

      listaAlertas: [
        "alertas_gerais_alteracao_recente_qsa",
        "alertas_gerais_associacao_cnpj_atipico_faixa_etaria",
        "alertas_gerais_cpf_administrador_de_cnpj",
        "alertas_gerais_empresa_ativa_exterior",
        "alertas_gerais_empresa_fundacao_recente",
        "alertas_gerais_encerradas_falencia_recuperacao",
        "alertas_gerais_excesso_empresas_encerradas",
        "alertas_gerais_extensao_regional_nacional",
        "alertas_gerais_geo_renda_incompativel_perfil_empresas",
        "alertas_gerais_historico_recente_empresa_encerrada",
        "alertas_gerais_montante_total_capital_social_elevado",
        "alertas_gerais_nao_baixadas_falencia_recuperacao",
        "alertas_gerais_ongs_fundacoes_partidos",
        "alertas_gerais_perfil_empresario",
        "alertas_gerais_polo_atuacao_cnae",
        "alertas_gerais_qsa_vinculo_parental",
        "alertas_gerais_quadros_societarios_estaveis",
        "alertas_gerais_socio_pj_situacao_cadastral_especial",
        "alertas_gerais_tempo_fundacao_elevado",
        "alertas_gerais_top5_empresas_fora_uf_cpf",
        "alertas_gerais_vinculo_holding_familiar",
        "alertas_gerais_vinculo_societario_recente",
      ],
    };
  },

  watch: {
    resultados(newValue, oldValue) {
      if (Object.keys(newValue).length !== 0) {
        this.atualizaAlertas();
      }
    },
  },

  methods: {
    atualizaAlertas() {
      if (Object.keys(this.resultados).length !== 0) {
        for (let key in this.resultados) {
          if (
            key.startsWith("alertas_gerais_") &&
            this.resultados[key] === true
          ) {
            const idAlerta = key.replace("alertas_gerais_", "");
            this.ativaAlerta(idAlerta);
          }
        }
      }
    },

    goTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    alteraBlocos() {
      this.abreBloco = !this.abreBloco;
      this.alteraBloco(this.abreBloco, 0);
    },

    abreEsteBloco() {
      this.abreBloco = true;
    },

    // fechaEsteBloco() {
    //   this.abreBloco = true;
    // },
    ...mapActions(blocosSuperBoxStore, ["alteraBloco"]),
    ...mapActions(storeAlertasSuperBox, ["ativaAlerta"]),
  },

  created() {},

  computed: {
    blocoPFConexoesEmpresas() {
      return this.listaBlocos.find(
        (bloco) => bloco.descricao === "Conexões com Empresas"
      );
    },

    msgErro() {
      const chave = this.blocoPFConexoesEmpresas.box;
      if (this.consultaErros[chave] !== undefined) {
        return this.consultaErros[chave];
      } else {
        return "";
      }
    },

    iconOpenClose() {
      if (this.abreBloco) {
        return "/img/superbox/icon-dropdown.png";
      } else {
        return "/img/superbox/icon-dropdown-abrir.png";
      }
    },

    // resultadoTratado() {
    //   const objetoTratado = {};
    //   for (const [chave, valor] of Object.entries(this.resultados)) {
    //     if (!chave.includes("flag") && !chave.includes("_ind_")) {
    //       objetoTratado[chave] =
    //         valor !== undefined && valor !== null ? valor : "-";
    //     } else {
    //       objetoTratado[chave] = valor;
    //     }
    //   }
    //   return objetoTratado;
    // },

    resultadoTratado() {
      const objetoCopiado = { ...this.resultados };

      for (const chave in objetoCopiado) {
        if (
          objetoCopiado[chave] === null &&
          !chave.includes("flag") &&
          !chave.includes("_ind_")
        ) {
          objetoCopiado[chave] = "-";
        }
      }
      return objetoCopiado;
    },

    ...mapState(blocosSuperBoxStore, {
      openBlocos: "openBlocos",
    }),
  },

  mounted() {
    this.atualizaAlertas();
  },
};
</script>

<style lang="scss" scoped>
.rowSec {
  width: 950px;
  display: flex;
  flex-direction: row;
  margin: 10px 5px;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
