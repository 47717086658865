<template>
  <div class="content" :class="{ '-vert': abreBloco }">
    <div class="icons" :class="{ '-vert': abreBloco }">
      <button
        class="buttonSB"
        id="openClose5"
        @click="abrirChatPersonalizado('MapaRiscos')"
      >
        <span class="material-symbols-outlined icone-chat"> smart_toy </span>
      </button>
      <button class="buttonSB" id="openClose5" @click="alteraBlocos()">
        <img class="icon-box" :src="iconOpenClose" />
      </button>
      <b-tooltip
        v-if="abreBloco && exibirTootipsConsultas"
        :target="'openClose5'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Fechar
      </b-tooltip>
      <b-tooltip
        v-if="!abreBloco && exibirTootipsConsultas"
        :target="'openClose5'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Abrir
      </b-tooltip>
      <button class="buttonSB" id="goTop5" @click="goTop">
        <img class="icon-box" :src="iconToTop" />
      </button>
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="'goTop5'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Voltar ao Topo
      </b-tooltip>
    </div>
    <div class="dropdown" :class="{ '-vert dropdown--open': abreBloco }">
      <HeaderBlocos :bloco="blocoPFMapaRiscos" :abreBloco="abreBloco" />
      <div
        class="body"
        v-if="Object.keys(this.resultados).length !== 0"
        :class="{ '-vert body--aberto': abreBloco }"
        id="Mapa-riscos"
      >
        <div class="mapaGeral">
          <div
            class="linhas-riscos"
            v-for="(risco, i) in mapaGeralRiscos"
            :key="i"
          >
            <div
              class="tipo sb-2 sb-right"
              :style="{
                'background-color': risco.cor,
                color: i < 3 ? 'black' : 'white',
                'font-weight': i == 5 ? '700' : '400',
              }"
              :id="'BlocoPFMapaRiscos-nome-' + i"
            >
              <span class="box-icon">
                <img class="icon-tipo" :src="risco.icone" />
              </span>
              <p class="tipo-text">
                {{ risco.nome }}
              </p>
            </div>
            <div
              class="valor sb-1 sb-right"
              :id="'BlocoPFMapaRiscos-valor-' + i + '-' + risco.valor"
            >
              <span class="box-valor" v-if="risco.valor == 'P'">
                <img
                  class="icon-valor left"
                  src="/img/superbox/mapa-riscos-prat.png"
                />
                <img
                  class="icon-valor"
                  src="/img/superbox/mapa-riscos-cust-cinza.png"
                />
              </span>
              <span class="box-valor" v-if="risco.valor == 'C'">
                <img
                  class="icon-valor left"
                  src="/img/superbox/mapa-riscos-prat-cinza.png"
                />
                <img
                  class="icon-valor"
                  src="/img/superbox/mapa-riscos-cust.png"
                />
              </span>
            </div>

            <div
              class="score sb-1"
              :class="{
                sonar: i == 5,
              }"
            >
              {{ risco.score }}
            </div>
          </div>
          <div class="saibaMais sb-4">
            <span class="saibaMaix-text"
              >Impulsione sua decisão com uma
              <strong class="strong"> CUSTOMIZAÇÃO </strong>
            </span>

            <button class="btn-contato" @click="goContato">Contato</button>
          </div>
        </div>
        <div class="grafBox">
          <div class="graf" v-if="this.liberaChart">
            <RadarChart :data="mapaGeralRiscos" :score="resultados" />
          </div>
          <div class="detalhes">
            <div class="ranking">
              <span class="rankTexto text-center" :class="getRankColorClass()">
                {{ rankSonar }}
              </span>
              <span class="rankDetalhe text-center">
                {{ textoRankSonar }}
              </span>
            </div>
            <div class="texto">
              {{ detalhesSonar }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="Object.keys(this.resultados).length === 0 && msgErro == ''">
        <Loading class="spinner" />
      </div>
      <md-card
        v-if="Object.keys(this.resultados).length === 0 && msgErro != ''"
        class="conteudo-horizontal-centralizado"
      >
        <md-card-content>
          {{ msgErro }}
        </md-card-content>
      </md-card>
    </div>
    <div v-if="abreBloco && liberaChart">
      <div v-for="(tt, i) in tooltipsBloco5Filtrado" :key="i">
        <b-tooltip
          v-if="exibirTootipsConsultas"
          :target="tt.target"
          triggers="hover"
          placement="left"
          variant="dark"
          class="tooltipTexts"
          boundary="window"
        >
          <div v-html="tt.texto"></div>
          <div v-if="mapaGeralRiscos">
            <div v-for="(item, j) in mapaGeralRiscos" :key="j">
              <span
                v-if="
                  tt.target.includes('BlocoPFMapaRiscos-valor-' + j) &&
                  mapaGeralRiscos[j].codigo != '' &&
                  mapaGeralRiscos[j].codigo != undefined
                "
              >
                <br />
                Código do modelo:
                {{ mapaGeralRiscos[j].codigo }}
              </span>
            </div>
          </div>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { storeAlertasSuperBox } from "@/stores/superbox-alertas.js";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { mapState, mapActions } from "pinia";
import HeaderBlocos from "@/components/SuperBox/HeaderBlocos.vue";
import RadarChart from "@/components/SuperBox/RadarChart.vue";
import Loading from "@/components/Loading";

import listaTooltips from "@/helpers/lista_tooltips_superbox.json";

export default {
  name: "BlocoPFMapaRiscos",

  components: { HeaderBlocos, RadarChart, Loading },

  props: {
    listaBlocos: Array,
    resultados: Object,
    consultaErros: Object,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {
      iconToTop: "/img/superbox/icon-totop.png",
      iconChat: "/img/assistente-chat/icon-chat-ia.png",
      abreBloco: false,
      mapaGeralRiscos: [],
      liberaChart: false,
    };
  },
  watch: {
    resultados(newValue, oldValue) {
      if (Object.keys(newValue).length !== 0) {
        this.atualizaValores();
      }
    },
    // resultados: {
    //   handler: "atualizaValores",
    //   immediate: false,
    //   deep: true,
    // },
  },
  methods: {
    goContato() {
      this.$router.push("/suporte/contato");
    },

    atualizaValores() {
      this.liberaChart = false;

      if (this.resultados != null) {
        this.mapaGeralRiscos = [
          {
            nome: "Risco de Crédito",
            cor: "#bdd7ee",
            icone: "/img/superbox/mapa-riscos-01-black.png",
            valor: this.resultadoTratado.risco_credito_classificacao,
            score: this.resultadoTratado.risco_credito_score,
            codigo: this.resultadoTratado.risco_credito_codigo,
          },
          {
            nome: "Risco de Localidade",
            cor: "#8ea9db",
            icone: "/img/superbox/mapa-riscos-02-black.png",
            valor: this.resultadoTratado.risco_localidade_classificacao,
            score: this.resultadoTratado.risco_localidade_score,
            codigo: this.resultadoTratado.risco_localidade_codigo,
          },
          {
            nome: "Potencial de Compra",
            cor: "#5b9bd5",
            icone: "/img/superbox/mapa-riscos-03-black.png",
            valor: this.resultadoTratado.potencial_compra_classificacao,
            score: this.resultadoTratado.potencial_compra_score,
            codigo: this.resultadoTratado.potencial_compra_codigo,
          },
          {
            nome: "Fragilidade Social",
            cor: "#4472c4",
            icone: "/img/superbox/mapa-riscos-04.png",
            valor: this.resultadoTratado.fragilidade_social_classificacao,
            score: this.resultadoTratado.fragilidade_social_score,
            codigo: this.resultadoTratado.fragilidade_social_codigo,
          },
          {
            nome: "Score de Longevidade",
            cor: "#1f4e78",
            icone: "/img/superbox/mapa-riscos-05.png",
            valor: this.resultadoTratado.longevidade_classificacao,
            score: this.resultadoTratado.longevidade_score,
            codigo: this.resultadoTratado.longevidade_codigo,
          },
          {
            nome: "Sonar Score",
            cor: "#7030a0",
            icone: "/img/superbox/mapa-riscos-06.png",
            valor: this.resultadoTratado.sonar_classificacao,
            score: this.resultadoTratado.sonar_score,
            codigo: "",
          },
        ];

        this.liberaChart = true;

        if (this.resultados.alertas_gerais_score_sonar_baixo) {
          this.ativaAlerta("score_sonar_baixo");
        }
        if (this.resultados.alertas_gerais_score_sonar_elevado) {
          this.ativaAlerta("score_sonar_elevado");
        }
      }
    },

    getRankColorClass() {
      const score = this.resultados.sonar_score;

      if (score <= 1000 && score >= 901) {
        return "rankColorA";
      } else if (score <= 900 && score >= 701) {
        return "rankColorB";
      } else if (score <= 700 && score >= 501) {
        return "rankColorC";
      } else if (score <= 500 && score >= 301) {
        return "rankColorD";
      } else if (score <= 300 && score >= 101) {
        return "rankColorE";
      } else {
        return "rankColorF";
      }
    },

    goTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    alteraBlocos() {
      this.abreBloco = !this.abreBloco;
      this.alteraBloco(this.abreBloco, 0);
    },

    abreEsteBloco() {
      this.abreBloco = true;
    },

    getRatingTextDetails(prefix, ranges) {
      const score = this.resultados.sonar_score;

      for (const range of ranges) {
        if (score <= range.max && score >= range.min) {
          return `${prefix} ${range.label}`;
        }
      }

      return `${prefix} Indefinido`;
    },

    abrirChatPersonalizado(tipo) {
      this.recebeChatPersonalizado(tipo);
    },
    // fechaEsteBloco() {
    //   this.abreBloco = true;
    // },
    ...mapActions(blocosSuperBoxStore, ["alteraBloco"]),
    ...mapActions(storeAlertasSuperBox, ["ativaAlerta"]),

    ...mapActions(chatOutboxStore, ["recebeChatPersonalizado"]),
  },

  created() {
    if (Object.keys(this.resultados).length !== 0) {
      this.atualizaValores();
    }
  },

  computed: {
    blocoPFMapaRiscos() {
      return this.listaBlocos.find(
        (bloco) => bloco.descricao === "Mapa Geral de Riscos"
      );
    },

    msgErro() {
      const chave = this.blocoPFMapaRiscos.box;
      if (this.consultaErros[chave] !== undefined) {
        return this.consultaErros[chave];
      } else {
        return "";
      }
    },

    tooltipsBloco5() {
      return listaTooltips.bloco05;
    },

    resultadoTratado() {
      const objetoCopiado = { ...this.resultados };

      for (const chave in objetoCopiado) {
        if (
          objetoCopiado[chave] === null &&
          !chave.includes("flag") &&
          !chave.includes("_ind_")
        ) {
          objetoCopiado[chave] = "-";
        }
      }
      return objetoCopiado;
    },

    tooltipsBloco5Filtrado() {
      const filteredTooltips = listaTooltips.bloco05.filter((tt) => {
        if (tt.target.startsWith("BlocoPFMapaRiscos-valor-")) {
          const index = parseInt(tt.target.split("-")[2]);
          if (index >= 0 && index < this.mapaGeralRiscos.length) {
            return this.mapaGeralRiscos[index].valor == tt.target.split("-")[3];
          }
          return true;
        } else {
          return true;
        }
      });
      return filteredTooltips;
    },

    iconOpenClose() {
      if (this.abreBloco) {
        return "/img/superbox/icon-dropdown.png";
      } else {
        return "/img/superbox/icon-dropdown-abrir.png";
      }
    },

    rankSonar() {
      return this.getRatingTextDetails("Rating", [
        { min: 901, max: 1000, label: "A: Próspero" },
        { min: 701, max: 900, label: "B: Ascendente" },
        { min: 501, max: 700, label: "C: Estável" },
        { min: 301, max: 500, label: "D: Emergente" },
        { min: 101, max: 300, label: "E: Suscetível" },
        { min: 0, max: 100, label: "F: Vulnerável" },
      ]);
    },

    textoRankSonar() {
      return this.getRatingTextDetails("Faixas entre", [
        { min: 901, max: 1000, label: "901 e 1000 pontos." },
        { min: 701, max: 900, label: "701 e 900 pontos." },
        { min: 501, max: 700, label: "501 e 700 pontos." },
        { min: 301, max: 500, label: "301 e 500 pontos." },
        { min: 101, max: 300, label: "101 e 300 pontos." },
        { min: 0, max: 100, label: "0 e 100 pontos." },
      ]);
    },

    detalhesSonar() {
      return this.getRatingTextDetails("Perfis classificados com", [
        {
          min: 901,
          max: 1000,
          label:
            "nível geral elevado nos indicadores avaliados, apresentando ótimo potencial socioeconômico.",
        },
        {
          min: 701,
          max: 900,
          label:
            "nível geral adequado nos indicadores avaliados, apresentando bom potencial socioeconômico.",
        },
        {
          min: 501,
          max: 700,
          label: "bom equilíbrio geral nos índices avaliados.",
        },
        {
          min: 301,
          max: 500,
          label:
            "baixa exposição às adversidades e com bom potencial de recuperação socioeconômica.",
        },
        {
          min: 101,
          max: 300,
          label:
            "média exposição às adversidades e com algum potencial de recuperação socioeconômica.",
        },
        {
          min: 0,
          max: 100,
          label:
            "alto grau de vulnerabilidade socioeconômica e em condições de severas adversidades.",
        },
      ]);
    },

    ...mapState(blocosSuperBoxStore, {
      openBlocos: "openBlocos",
    }),
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.mapaGeral {
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.grafBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 16px;
}
.graf {
  height: 70%;
  max-width: 350px;
  cursor: default;
}
.detalhes {
  border: solid 1px #4472c4;
  border-radius: 5px;
  height: 70px;
  display: flex;
  gap: 10px;
  padding: 3px;
  font-size: 12px;
  width: 100%;
  cursor: default;
  background-color: white;

  .ranking {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 35%;
    .rankTexto {
      font-weight: 500;
      &.rankColorA {
        color: #0070c0;
      }

      &.rankColorB {
        color: #70ad47;
      }

      &.rankColorC {
        color: #ffc000;
      }

      &.rankColorD {
        color: #ed7d31;
      }

      &.rankColorE {
        color: #ff0000;
      }

      &.rankColorF {
        color: #c00000;
      }
    }

    .rankDetalhe {
      font-size: 11px;
      cursor: default;
    }
  }

  .texto {
    font-size: 12px;
    width: 65%;
    display: flex;
    align-items: center;
    line-height: 12px;
  }
}

.linhas-riscos {
  display: flex;
  max-width: 315px;
  height: 65px;
  font-size: 12px;

  .tipo {
    height: 60px;
    border-radius: 5px;
    color: white;
    display: flex;
    align-items: center;
    padding: 5px 10px 5px 5px;
    cursor: default;

    .box-icon {
      width: 45%;
      height: 100%;
      display: flex;
      align-items: center;
      .icon-tipo {
        height: 35px;
        margin: 0 5px;
      }
    }

    .tipo-text {
      margin: 0;
      width: 70%;
      margin: 0 0 0 15px;
      line-height: 14px;
    }
  }

  .valor {
    height: 60px;
    border: solid 1px #0070c0;
    border-radius: 10px;
    cursor: default;
    @include flex-center(row);

    .icon-valor {
      &.left {
        margin-right: 10px;
      }
    }
  }

  .score {
    height: 60px;
    margin: 0px;
    border: solid 1px #0070c0;
    border-radius: 10px;
    @include flex-center(row);
    cursor: default;

    &.sonar {
      background-color: #7030a0;
      color: white;
      font-weight: 700;
      border: 1px solid #7030a0;
    }
  }
}

.saibaMais {
  display: flex;
  background-color: #374567;
  border-radius: 5px;
  color: white;
  padding: 5px 5px 5px 10px;
  height: 70px;
  align-items: center;

  .saibaMaix-text {
    color: white;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;

    .strong {
      color: white;
    }
  }
  .saibaMaix-img {
    height: 40px;
    cursor: pointer;
  }
}

.btn-contato {
  width: 213px;
  height: 35px;
  margin: 0 10px;
  transition: 0.5s;
  background-image: url(/img/tela-inicial/Botao-Nova.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  color: white;
  font-weight: 600;
  line-height: 21px;
  font-size: 14px;

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
}

.icone-chat {
  // border-radius: 50%;
  // border: 2px solid #4356A5;
  color: #4356a5;
  padding: 1px;
  font-size: 22px;
  position: relative;
  top: 5px;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
