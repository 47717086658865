var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"tabelaResultados-passagens",attrs:{"id":"Passagens-tab"}},[_c('div',{staticClass:"linha-cabecalho"},[_c('span',{staticClass:"cabecalho-pass vazio sb-right sb-2"}),_vm._l((_vm.indicesSuperiores),function(indiceSuperior,indexSuperior){return _c('span',{key:indexSuperior,staticClass:"cabecalho-pass azul-claro text-center",class:{
          'sb-1': indexSuperior != 1 || indexSuperior != 2,
          'sb-2': indexSuperior == 1 || indexSuperior == 2,
          'sb-right': indexSuperior != 7,
        },staticStyle:{"padding":"0 15px"},attrs:{"id":'BlocoPFPassagens-sup-' + indexSuperior}},[_vm._v(" "+_vm._s(indiceSuperior)+" ")])})],2),_c('div',{staticClass:"tabela-corpo"},_vm._l((_vm.respostaPassagens),function(resposta,indexResposta){return _c('div',{key:indexResposta,staticClass:"linha-resultados"},[_c('div',{staticClass:"cabecalho-resultados sb-2 sb-top sb-right text-left",class:{
            'azul-escuro': indexResposta == 0,
            'azul-claro': indexResposta != 0,
          }},[_c('span',{staticClass:"cell",attrs:{"id":'BlocoPFPassagens-lateral-' + indexResposta}},[_vm._v(" "+_vm._s(_vm.indicesLaterais[indexResposta])+" ")])]),_c('span',{staticClass:"text-result sb-1 sb-right text-center"},[_c('span',{staticClass:"cell flag"},[_c('check',{attrs:{"valor":resposta.flagDeHistorico}})],1)]),_vm._l((resposta.outrosValores),function(valor,indexValor){return _c('span',{key:indexValor,staticClass:"text-result text-center",class:{
            'sb-1': indexValor != 0 || indexValor != 1,
            'sb-2': indexValor == 0 || indexValor == 1,
            'sb-right': indexValor != 6,
          }},[_c('span',{staticClass:"cell"},[_vm._v(" "+_vm._s(valor)+" "),(
                valor != '-' &&
                valor > 0 &&
                valor != 1 &&
                (indexValor == 0 || indexValor == 1)
              )?_c('span',{staticStyle:{"margin-left":"3px"}},[_vm._v(" meses ")]):_vm._e(),(
                valor != '-' &&
                valor == 1 &&
                (indexValor == 0 || indexValor == 1)
              )?_c('span',[_vm._v(" mes ")]):_vm._e()])])}),_c('span',{staticClass:"text-result sb-1 text-center"},[_c('span',{staticClass:"cell flag"},[_c('check',{attrs:{"valor":resposta.flagDeAlerta}})],1)])],2)}),0)]),_vm._l((_vm.tooltipsBloco6),function(tt,i){return _c('div',{key:i},[(_vm.exibirTootipsConsultas)?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":tt.target,"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[_c('div',{domProps:{"innerHTML":_vm._s(tt.texto)}})]):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }