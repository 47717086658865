<template>
  <div style="min-height: 130px">
    <div
      class="box-bloco"
      @click="scrollToBlock()"
      :id="'bloco' + index"
      :class="{
        mb: index != 6 && index != 7 && index != 8,
        ativo: status[index] != 'erro',
        inativo: status[index] == 'erro',
      }"
    >
      <span class="box-icon">
        <img
          class="bloco-image"
          :src="status[index] != 'erro' ? bloco.imagem : bloco.imagemCinza"
        />
      </span>
      <div class="bloco-title text-center">
        {{ bloco.titulo }}
      </div>
      <div class="divisoria">---------------</div>
      <div class="bloco-name text-center">
        <span>
          {{ bloco.descricao }}
        </span>
      </div>
      <div class="bloco-status text-center">
        <img
          v-if="status[index] == 'ok'"
          class="bloco-status-image"
          src="/img/superbox/statusTrue.png"
        />
        <img
          v-if="status[index] == 'loading'"
          class="bloco-status-image"
          src="/img/superbox/statusFalse.gif"
        />
        <span
          v-if="status[index] == 'erro'"
          class="material-symbols-outlined bloco-status-icon"
        >
          warning
        </span>
        <!-- <img
          v-if="status[index] == 'erro'"
          class="bloco-status-image"
          src="/img/superbox/statusRocket.png"
        /> -->
      </div>
    </div>
    <b-tooltip
      v-if="bloco.tooltip != '' && exibirTootipsConsultas"
      :target="'bloco' + index"
      triggers="hover"
      placement="bottom"
      variant="dark"
      class="tooltipTexts"
      boundary="window"
    >
      {{ bloco.tooltip }}
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: "BlocoStatus",

  props: {
    status: Array,
    bloco: Object,
    index: Number,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {};
  },

  methods: {
    scrollToBlock() {
      this.$emit("scroll-to-block", this.index, this.bloco);
    },
  },

  created() {},

  computed: {},
};
</script>

<style lang="scss" scoped>
.box-bloco {
  width: 141px;
  height: 110px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 5px 6px 5px 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  color: $outbox-medium-blue;

  &.mb {
    margin-bottom: 40px !important;
  }

  &.ativo {
    background-image: url(/img/superbox/BG_Bloco.png);
  }

  &.inativo {
    background-image: url(/img/superbox/BG_Bloco-cinza.png);
    opacity: 0.8;
    color: gray;
  }
}

.bloco-title {
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  bottom: 6px;
}
.bloco-name {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 14px;
  height: 30px;
  padding: 0 10px;
  position: relative;
  bottom: 8px;
  line-height: 18px;
}
.box-icon {
  width: 153px;
  text-align: center;
  height: 14px;
}
.bloco-image {
  height: 50px;
  position: relative;
  bottom: 34px;
}

.bloco-status-image {
  height: 15px;
  position: relative;
  right: 2px;
}
.bloco-status-icon {
  font-size: 17px;
  position: relative;
  right: 2px;
  color: white;
  top: 5px;
}

.divisoria {
  position: relative;
  bottom: 18px;
  height: 5px;
}
</style>
