<template>
  <div class="content">
    <div class="icons">
      <button class="buttonSB" id="openClose8" @click="alteraBlocos()">
        <img class="icon-box" :src="iconOpenClose" />
      </button>
      <b-tooltip
        v-if="abreBloco && exibirTootipsConsultas"
        :target="'openClose8'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Fechar
      </b-tooltip>
      <b-tooltip
        v-if="!abreBloco && exibirTootipsConsultas"
        :target="'openClose8'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Abrir
      </b-tooltip>
      <button class="buttonSB" id="goTop8" @click="goTop">
        <img class="icon-box" :src="iconToTop" />
      </button>
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="'goTop8'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Voltar ao Topo
      </b-tooltip>
    </div>
    <div class="dropdown" :class="{ 'dropdown--open': abreBloco }">
      <HeaderBlocos :bloco="blocoPFRenda" />

      <div
        class="body"
        :class="{ 'body--aberto': abreBloco }"
        v-if="Object.keys(this.resultados).length !== 0"
        id="Renda-presumida"
      >
        <div class="row" v-if="abreBloco">
          <div class="bg">
            <span class="legenda -pot" :id="'BlocoPFRenda-potencial'"
              >Potencial de Compra</span
            >
            <span class="legenda -pon" :id="'BlocoPFRenda-pontualidade'"
              >Pontualidade de Pagamento</span
            >

            <div class="atr-box -limG">
              <span class="atr-titulo" :id="'BlocoPFRenda-global'">
                Limite<br />
                Global
              </span>
              <span class="atr-valor">
                <span class="caixa"> R$ </span>
                <span class="resultado">
                  {{
                    formatarNumeroComPonto(
                      resultadoTratado.boxlsvar002_num_limite_global_sugerido
                    )
                  }}
                </span>
              </span>
            </div>
            <div class="atr-box -LimP">
              <span class="atr-titulo" :id="'BlocoPFRenda-parcela'">
                Limite<br />
                Parcela
              </span>
              <span class="atr-valor">
                <span class="caixa"> R$ </span>
                <span class="resultado">
                  {{
                    formatarNumeroComPonto(
                      resultadoTratado.boxlsvar002_num_limite_parcela_sugerido
                    )
                  }}
                </span>
              </span>
            </div>
            <div class="atr-box -Com">
              <span class="atr-titulo" :id="'BlocoPFRenda-comprometimento'">
                Comprometimento<br />
                Assumido
              </span>
              <span class="atr-valor">
                <span class="caixa"> % </span>
                <span class="resultado">
                  {{
                    resultadoTratado.boxlsvar002_num_percentual_comprometimento_renda_parcela
                  }}
                </span>
              </span>
            </div>
            <div class="atr-box -Num">
              <span class="atr-titulo" :id="'BlocoPFRenda-parcelas'">
                Número de<br />
                Parcelas
              </span>
              <span class="atr-valor">
                <span class="caixa"> # </span>
                <span class="resultado">
                  {{ resultadoTratado.boxlsvar002_num_quantidade_parcelas }}
                </span>
              </span>
            </div>
            <span class="box-esp" id="BlocoPFRenda-renda"></span>
            <span class="valor -rend" id="valor">
              <span class="renda-valor">
                {{ totalValor(resultadoTratado.boxpipf002_renda_presumida) }}
              </span>

              <span class="sub">
                {{ legendaValor(resultadoTratado.boxpipf002_renda_presumida) }}
              </span>
            </span>
            <b-tooltip
              v-if="abreBloco && exibirTootipsConsultas"
              :target="'valor'"
              triggers="hover"
              placement="bottom"
              variant="dark"
              class="tooltipTexts"
              boundary="window"
            >
              {{ formatarEmReais(resultadoTratado.boxpipf002_renda_presumida) }}
            </b-tooltip>
            <span class="valor -pot">
              {{ resultadoTratado.faixas_score_especifico_boxpp002 }}
            </span>
            <span class="valor -pon">
              {{ resultadoTratado.faixas_score_especifico_boxcsvar002 }}
            </span>
          </div>
          <div class="box-graf blue">
            <img :src="escalaPotencialCompra" alt="" />
          </div>
          <div class="box-graf purple">
            <img :src="escalaPontPagamento" alt="" />
          </div>
          <div class="interpr-box">
            <span class="interpr-titulo"> Interpretação </span>
            <span class="tabTextBoxInterpr">
              <span class="interpr-valor" v-html="interpretacaoFormatada"></span
            ></span>
          </div>
        </div>
        <div v-for="(tt, i) in tooltips" :key="i">
          <b-tooltip
            v-if="abreBloco && exibirTootipsConsultas"
            :target="tt.target"
            triggers="hover"
            placement="bottom"
            variant="dark"
            class="tooltipTexts"
            boundary="viewport"
          >
            <div v-html="tt.texto"></div>
          </b-tooltip>
        </div>
      </div>
      <div v-if="Object.keys(this.resultados).length === 0 && msgErro == ''">
        <Loading class="spinner" />
      </div>
      <md-card
        v-if="Object.keys(this.resultados).length === 0 && msgErro != ''"
        class="conteudo-horizontal-centralizado"
      >
        <md-card-content>
          {{ msgErro }}
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { storeAlertasSuperBox } from "@/stores/superbox-alertas.js";
import { mapState, mapActions } from "pinia";
import HeaderBlocos from "@/components/SuperBox/HeaderBlocos.vue";
import Loading from "@/components/Loading";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";

export default {
  name: "BlocoPFRenda",

  components: { HeaderBlocos, Loading },

  props: {
    listaBlocos: Array,
    openChild: Boolean,
    resultados: Object,
    consultaErros: Object,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {
      iconToTop: "/img/superbox/icon-totop.png",
      abreBloco: false,
    };
  },

  watch: {
    resultados(newValue, oldValue) {
      if (Object.keys(newValue).length !== 0) {
        this.atualizaAlertas();
      }
    },
  },

  methods: {
    atualizaAlertas() {
      if (Object.keys(this.resultados).length !== 0) {
        for (let key in this.resultados) {
          if (
            key.startsWith("alertas_gerais_") &&
            this.resultados[key] === true
          ) {
            const idAlerta = key.replace("alertas_gerais_", "");
            this.ativaAlerta(idAlerta);
          }
        }
      }
    },

    totalValor(valor) {
      if (valor != -1 && valor != "-") {
        if (Math.abs(valor) >= 1000000000) {
          const valorEmBilhoes = valor / 1000000000;
          return this.formatarValor(valorEmBilhoes);
        } else if (Math.abs(valor) >= 1000000) {
          const valorEmMilhoes = valor / 1000000;
          return this.formatarValor(valorEmMilhoes);
        } else if (Math.abs(valor) >= 1000) {
          const valorEmMilhares = valor / 1000;
          return this.formatarValor(valorEmMilhares);
        } else {
          return this.formatarValor(valor);
        }
      } else {
        return valor;
      }
    },

    formatarValor(valor) {
      let valorString = valor.toString();
      let [inteiro, decimal] = valorString.split(".");

      if (decimal) {
        if (decimal.length > 1) {
          decimal = decimal[0];
        }
        if (decimal === "0") {
          decimal = "";
        }
      }

      valorString = decimal ? `${inteiro},${decimal}` : inteiro;

      let valorFormatado = parseFloat(
        valorString.replace(",", ".")
      ).toLocaleString("pt-BR", {
        minimumFractionDigits: decimal ? 1 : 0,
        maximumFractionDigits: decimal ? 1 : 0,
      });

      return valorFormatado;
    },

    legendaValor(valor) {
      if (valor != -1 && valor != "-") {
        if (Math.abs(valor) >= 1000000000) {
          const valorEmBilhoes = (valor / 1000000000).toFixed(2);
          if (parseFloat(valorEmBilhoes) >= 2) {
            return "bilhões";
          } else {
            return "bilhão";
          }
        } else if (Math.abs(valor) >= 1000000) {
          const valorEmMilhoes = (valor / 1000000).toFixed(2);
          if (parseFloat(valorEmMilhoes) >= 2) {
            return "milhões";
          } else {
            return "milhão";
          }
        } else if (Math.abs(valor) >= 1000) {
          return "mil";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },

    formatarEmReais(numero) {
      if (numero != "-") {
        return numero.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      } else {
        return numero;
      }
    },

    formatarNumeroComPonto(numero) {
      if (numero != "-") {
        const numeroString = numero.toString().replace(".", ",");
        const partes = numeroString.split(",");
        partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return partes.join(",");
      } else {
        return numero;
      }
    },

    goTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    alteraBlocos() {
      this.abreBloco = !this.abreBloco;
      this.alteraBloco(this.abreBloco, 0);
    },

    abreEsteBloco() {
      this.abreBloco = true;
    },

    // fechaEsteBloco() {
    //   this.abreBloco = true;
    // },
    ...mapActions(blocosSuperBoxStore, ["alteraBloco"]),
    ...mapActions(storeAlertasSuperBox, ["ativaAlerta"]),
  },

  created() {},

  computed: {
    resultadoTratado() {
      const objetoCopiado = { ...this.resultados };

      for (const chave in objetoCopiado) {
        if (
          objetoCopiado[chave] === null &&
          !chave.includes("flag") &&
          !chave.includes("_ind_")
        ) {
          objetoCopiado[chave] = "-";
        }
      }
      return objetoCopiado;
    },

    interpretacaoFormatada() {
      var interpretacao = this.resultadoTratado.interpretacao;

      if (interpretacao != "-") {
        var formatado = this.resultadoTratado.interpretacao.replace(
          /\./g,
          ".<br/> <br/>"
        );

        return formatado.replace(/R\$(\d+)|(\d+%)/g, (match, p1, p2) => {
          var valorDestacado;

          // Verifica se o valor é um número de porcentagem
          if (p2) {
            valorDestacado = p2;
          } else {
            // Caso contrário, formata como valor monetário
            var numeroFormatado = parseInt(p1).toLocaleString("pt-BR");
            valorDestacado = `R$ ${numeroFormatado}`;
          }

          return `<span style="
                  color: #3bc0bf;
                  font-weight: 500;
                  width: fit-content;
                ">${valorDestacado} </span>`;
        });
      } else {
        return interpretacao;
      }
    },

    blocoPFRenda() {
      return this.listaBlocos.find(
        (bloco) => bloco.descricao == "Renda Presumida"
      );
    },

    msgErro() {
      const chave = this.blocoPFRenda.box;
      if (this.consultaErros[chave] !== undefined) {
        return this.consultaErros[chave];
      } else {
        return "";
      }
    },

    escalaPotencialCompra() {
      const mapping = {
        "-": 0,
        Reduzido: 1,
        Baixo: 2,
        Usual: 3,
        Moderado: 4,
        Elevado: 5,
      };
      var variavel = this.resultadoTratado.faixas_score_especifico_boxpp002;

      const valor = mapping[variavel] !== undefined ? mapping[variavel] : 0;

      const endereco = `/img/superbox/renda-grafico/AZUL-${valor}.png`;
      return endereco;
    },

    escalaPontPagamento() {
      const mapping = {
        "-": 0,
        Baixíssimo: 1,
        Baixo: 2,
        Moderado: 3,
        Médio: 3,
        Alto: 4,
        Altíssimo: 5,
      };

      var variavel = this.resultadoTratado.faixas_score_especifico_boxcsvar002;
      const valor = mapping[variavel] !== undefined ? mapping[variavel] : 0;
      const endereco = `/img/superbox/renda-grafico/ROXO-${valor}.png`;
      return endereco;
    },

    iconOpenClose() {
      if (this.abreBloco) {
        return "/img/superbox/icon-dropdown.png";
      } else {
        return "/img/superbox/icon-dropdown-abrir.png";
      }
    },

    tooltips() {
      return listaTooltips.blocoRenda;
    },

    ...mapState(blocosSuperBoxStore, {
      openBlocos: "openBlocos",
    }),
  },

  mounted() {
    this.atualizaAlertas();
  },
};
</script>

<style lang="scss" scoped>
.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 5px;
  height: 515px;
  max-width: 950px;
}

.bg {
  background-image: url(/img/superbox/renda-grafico/blocoRenda-bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
  margin: 0 25px;
}

.box-graf {
  width: 170px;
  position: absolute;

  &.blue {
    top: 253px;
    left: 159px;
  }
  &.purple {
    top: 253px;
    right: 162px;
  }
}

.atr-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: default;
  gap: 10px;
  text-align: center;

  &.-limG {
    bottom: 35px;
    left: 15px;
  }

  &.-LimP {
    bottom: 35px;
    left: 197px;
  }

  &.-Com {
    bottom: 35px;
    right: 182px;
  }

  &.-Num {
    bottom: 35px;
    right: 15px;
  }
}

.atr-titulo {
  font-size: 20px;
  line-height: 21px;
  color: #0070c0;
  font-weight: 500;
}

.atr-valor {
  display: flex;
}

.legenda {
  font-size: 20px;
  line-height: 21px;
  font-weight: 500;
  color: #0070c0;
  position: absolute;
  cursor: default;

  &.-pot {
    top: 145px;
    left: 218px;
    width: 110px;
  }

  &.-pon {
    top: 145px;
    right: 219px;
    width: 132px;
    text-align: right;
  }
}

.box-esp {
  position: absolute;
  height: 20px;
  width: 85px;
  top: 164px;
  left: 434px;
}

.valor {
  font-size: 20px;
  line-height: 21px;
  position: absolute;
  text-align: left;
  display: flex;
  cursor: default;

  &.-rend {
    width: 143px;
    color: #0070c0;
    font-weight: 700;
    font-size: 32px;
    top: 204px;
    left: 406px;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &.-pot {
    top: 206px;
    left: 163px;
    color: white;
  }

  &.-pon {
    top: 206px;
    right: 158px;
    text-align: right;
    color: white;
  }
}

.resultado {
  border: solid 2px #0070c0;
  color: #0070c0;
  border-radius: 5px;
  width: 90px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.caixa {
  font-size: 16px;
  line-height: 21px;
  background-color: #0070c0;
  color: white;
  border-radius: 5px;
  margin-right: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub {
  font-size: 20px;
  font-weight: 500;
  line-height: 21px;
  color: #0070c0;
  text-align: center;
  position: relative;
  top: 10px;
}

.interpr-box {
  width: 212px;
  text-align: center;
  background-color: white;
  border-radius: 0 0 5px 5px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 370px;
  max-height: 240px;

  .interpr-titulo {
    background-color: #a69be0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    min-height: 40px;
    border-radius: 5px 5px 0 0;
    cursor: default;
    line-height: 18px;
  }

  .tabTextBoxInterpr {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    cursor: default;
    text-align: center;
    line-height: 16px;
    width: 100%;
    padding: 10px 10px 0 10px;
  }
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
